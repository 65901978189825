<script lang="ts">
  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"

  import {
    loadRssSourcesCategoriesLoading,
    loadRssSourcesLoading,
    rssSourcesByCategories
  } from "../stores/RssSourcesStore"

  export let uuid: string | undefined = undefined
</script>

{#if $loadRssSourcesLoading || $loadRssSourcesCategoriesLoading}
  <Loader size={40} />
{:else}
  {#each $rssSourcesByCategories as rssSourcesByCategory}
    <div class="rss-sources">
      <MenuItem
        selected={uuid === rssSourcesByCategory.rssSourceCategory.uuid}
        label={rssSourcesByCategory.rssSourceCategory.title}
        href={`/rss/feeds/category/${rssSourcesByCategory.rssSourceCategory.uuid}`}
        count={rssSourcesByCategory.rssSources.reduce((acc, s) => acc + s.unreaded, 0)}
      />
      {#each rssSourcesByCategory.rssSources as rssSource}
        <MenuItem
          sub
          selected={uuid === rssSource.uuid}
          label={rssSource.title}
          href={`/rss/feeds/source/${rssSource.uuid}`}
          count={rssSource.unreaded}
        />
      {/each}
    </div>
  {/each}
{/if}
