<script lang="ts">
  import { link } from "svelte-navigator"

  import type { Objective } from "../Objective"

  export let ancestors: Objective[]
</script>

<div class="breadcrumb">
  {#each ancestors as ancestor, i (ancestor.uuid)}
    {#if i !== 0}
      <div class="separaton">/</div>
    {/if}
    <a use:link class="crumb" href="/objectives/{ancestor.uuid}">{ancestor.data.name}</a>
  {/each}
</div>

<style>
  .breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .separaton {
    color: var(--text-unimportant-color);
    margin-left: 5px;
    margin-right: 5px;
  }

  .crumb,
  .separaton {
    font-size: 0.8rem;
  }

  .crumb {
    color: var(--text-primary-color);
    text-decoration: none;
  }

  .crumb:hover {
    text-decoration: underline;
  }
</style>
