<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { getFieldErrorMessage } from "../../utils"
  import type { RssSourceCategory } from "../models/RssSourceCategory"

  let loading: boolean = false
  let requestErrors: ApiErrors | undefined = undefined

  let title = ""

  $: titleError = getFieldErrorMessage("title", (k, p) => $_(k, p), requestErrors)

  function submit() {
    loading = true
    Api.rssSourcesCategories
      .createRssSourceCategory(title)
      .then(rssSourceCategory => {
        dispatch("created", { rssSourceCategory })
        loading = false
      })
      .catch((errors: ApiErrors) => {
        requestErrors = errors
        loading = false
      })
  }

  const dispatch = createEventDispatcher<{
    created: {
      rssSourceCategory: RssSourceCategory
    }
  }>()
</script>

<div class="modal">
  <label for="title">
    {$_("form.title")}
    <input id="title" type="text" bind:value={title} />
    {#if titleError}
      <div class="error">{titleError}</div>
    {/if}
  </label>
  <div>
    <button class="primary" aria-busy={loading} on:click={submit}>{$_("add")}</button>
  </div>
</div>

<style>
  .modal {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
    padding: 0 var(--s3);
  }
</style>
