import { writable } from "svelte/store"

import { Api } from "../../services/Api"
import type { Patch } from "../../utils"
import { createArrayWritableStore } from "../../utils"
import type { FsNode } from "../models/FsNode"

export const wantCreateDirectory = writable(false)
export const wantCreateNote = writable(false)
export const wantRenameFsNode = writable<FsNode | undefined>(undefined)
export const wantMoveFsNode = writable<FsNode | undefined>(undefined)
export const wantMoveMultiFsNodes = writable<FsNode[] | undefined>(undefined)
export const multiSelect = writable(false)

function createSelectedFsNodes() {
  const store = createArrayWritableStore<FsNode>([], b => b.uuid)
  return {
    ...store,
    select: (fsNode: FsNode) => {
      store.update(nodes => {
        const finded = nodes.find(n => n.uuid === fsNode.uuid)
        if (finded) {
          return [...nodes.filter(n => n.uuid !== fsNode.uuid)]
        } else {
          return [...nodes, fsNode]
        }
      })
    },
    deselect: () => store.update(() => [])
  }
}

export const selectedFsNodes = createSelectedFsNodes()

function createFsNodesStore() {
  const store = createArrayWritableStore<FsNode>([], b => b.uuid)
  return {
    ...store,
    move: (fsNode: FsNode, _: FsNode) => store.update(files => files.filter(f => f.uuid !== fsNode.uuid)),
    createDirectory,
    createNote,
    renameFsNodeAction,
    moveFsNodeAction,
    multiMoveAction,
    deleteFsNodeAction
  }
}

function createDirectory(parentUuid: string, name: string) {
  return Api.fsNodes.createDirectory(parentUuid, name).then(directory => {
    fsNodesStore.addItem(directory)
    wantCreateDirectory.update(() => false)
  })
}

function createNote(parentUuid: string, name: string) {
  return Api.fsNodes.createNote(parentUuid, name).then(directory => {
    fsNodesStore.addItem(directory)
    wantCreateDirectory.update(() => false)
  })
}

function renameFsNodeAction(
  fsNodeUuid: string,
  patch: {
    name?: Patch<string>
    content?: Patch<string>
  }
) {
  return Api.fsNodes.patchFsNode(fsNodeUuid, patch).then(updated => {
    fsNodesStore.updateItem(updated)
    selectedFsNodes.updateItem(updated)
    wantRenameFsNode.set(undefined)
  })
}

export const moveFsNodeActionLoading = writable(false)
function moveFsNodeAction(source: FsNode, target: FsNode) {
  return Api.fsNodes.moveFsNode(source.uuid, target.uuid).then(() => {
    fsNodesStore.move(source, target)
    wantMoveFsNode.set(undefined)
    selectedFsNodes.deselect()
  })
}

export const multiMoveActionLoading = writable(false)
function multiMoveAction(sources: FsNode[], target: FsNode) {
  return Promise.all(
    sources.map(source =>
      Api.fsNodes.moveFsNode(source.uuid, target.uuid).then(() => {
        fsNodesStore.move(source, target)
      })
    )
  )
}

function deleteFsNodeAction(fsNodeUuid: string) {
  return Api.fsNodes.deleteFsNode(fsNodeUuid)
}

export const fsNodesStore = createFsNodesStore()
