import { format } from "date-fns"
import type { Validator } from "idonttrustlikethat"

import { writable } from "svelte/store"

import type { RssFeed } from "./rss_feeds/models/RssFeed"
import type { ApiError, ApiErrors } from "./services/ApiError"

export const indefinable = <T>(validator: Validator<T>) => validator.nullable().map(s => (s ? s : undefined))

export function getFieldErrorMessage(
  field: string,
  intl: (key: string, params?: Record<string, string | number>) => string,
  errors?: ApiErrors
): string | undefined {
  if (errors) {
    const fieldsErrors = errors.errors && (errors.errors[field] as ApiError[] | undefined)
    if (fieldsErrors && fieldsErrors.length > 0) {
      const error = fieldsErrors[0]

      return intl(error.message, error.params)
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export function getRssFeedTitle(rssFeed: RssFeed) {
  if (rssFeed.readable?.title) {
    return rssFeed.readable?.title
  } else if (rssFeed.rss?.title) {
    return rssFeed.rss?.title
  } else {
    return rssFeed.rss_url
  }
}

export function getRssFeedDescription(rssFeed: RssFeed) {
  if (rssFeed.readable?.excerpt) {
    return rssFeed.readable?.excerpt
  }
}

export function queryString(queries: Record<string, string | number | undefined | null | boolean>): string {
  const querystrings = Object.keys(queries)
    .filter(key => !!queries[key])
    .map(key => `${key}=${encodeURI(String(queries[key]) || "")}`)
  if (querystrings.length > 0) {
    return `?${querystrings.join("&")}`
  } else {
    return ""
  }
}

export function filterMap<T>(list: T[], mapper: (item: T) => T, predicate: (item: T) => boolean) {
  return list.map(i => (predicate(i) ? mapper(i) : i))
}

export function groupBy<T extends object>(items: T[], extractKey: (item: T) => string | number) {
  const initial: Record<string | number, T[]> = {}
  return items.reduce((acc, i) => {
    const key = extractKey(i)
    const group = acc[key]
    if (group) {
      group.push(i)
      return acc
    } else {
      return { ...acc, [key]: [i] }
    }
  }, initial)
}

export function isMobile(): boolean {
  return !window.matchMedia("(min-width: 700px)").matches
}

export function sanitize(content: string): string {
  return stripScriptsWithRegex(sanitizeWithDom(content, sanitizeScripts))
}

export function stripScriptsWithRegex(html: string): string {
  // eslint-disable-next-line quotes
  const regex = `<script(?:(?!\/\/)(?!\/\*)[^'"]|"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\/\/.*(?:\n)|\/\*(?:(?:.|\s))*?\*\/)*?<\/script>`

  return html.replace(regex, regex)
}

export function sanitizeWithDom(content: string, f: (html: HTMLDivElement) => HTMLDivElement): string {
  const div = document.createElement("div")
  div.innerHTML = content

  return f(div).innerHTML
}

export function sanitizeScripts(html: HTMLDivElement): HTMLDivElement {
  const scripts = html.getElementsByTagName("script")
  let i = scripts.length
  while (i) {
    i -= 1
    const script = scripts[i]
    if (script.parentNode) {
      script.parentNode.removeChild(script)
    }
  }

  return html
}

export function dateFormat(date: Date): string {
  return format(date, "dd/MM/yyyy HH:mm")
}

export type Id = string | number

export interface TreeNode<D> {
  id: Id
  parent_id: Id | undefined | null
  children: TreeNode<D>[]
  data: D
}

export function stratify<D>(
  items: D[],
  id: (node: any) => Id = n => n.id,
  parentId: (node: any) => Id | undefined = n => n.parent_id
): TreeNode<D> | undefined {
  let nodeById = new Map<Id, TreeNode<D>>()

  for (let index = 0; index < items.length; index++) {
    const item = items[index]
    const nodeId = id(item)
    const parent_id = parentId(item)
    const node = {
      id: nodeId,
      parent_id,
      children: [],
      data: item
    }
    nodeById.set(nodeId, node)
  }

  let root: TreeNode<D> | undefined

  for (let index = 0; index < items.length; index++) {
    const item = items[index]
    const nodeId = id(item)
    const node = nodeById.get(nodeId)!
    const parent_id = node.parent_id

    if (parent_id) {
      const parent = nodeById.get(parent_id)
      if (parent) {
        parent.children.push(node)
      } else {
        // if the node is not present in nodeById it probably the root
        root = node
      }
    } else {
      root = node
    }
  }

  return root
}

export function createArrayWritableStore<DATA, ID = string | number>(initialData: DATA[], getId: (data: DATA) => ID) {
  const { subscribe, set, update } = writable<DATA[]>(initialData)
  return {
    subscribe,
    set,
    update,
    addItem: (item: DATA) => update(state => [item, ...state]),
    appendItem: (item: DATA) => update(state => [...state, item]),
    removeItem: (item: DATA) => update(state => state.filter(b => getId(b) !== getId(item))),
    updateItem: (item: DATA) =>
      update(state =>
        filterMap(
          state,
          _ => item,
          s => getId(s) === getId(item)
        )
      ),
    concat: (items: DATA[]) => update(state => state.concat(items))
  }
}

export type PatchReplace<V> = {
  type: "Replace"
  value: V
}

export type PatchDelete = {
  type: "Delete"
}

export type Patch<V> = PatchReplace<V> | PatchDelete
