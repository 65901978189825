<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"

  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Modal from "../../components/Modal.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import AddRssSourceCategoryModal from "../components/AddRssSourceCategoryModal.svelte"
  import EditRssSourceCategoryModal from "../components/EditRssSourceCategoryModal.svelte"
  import MyRssSource from "../components/MyRssSource.svelte"
  import MyRssSourceCategory from "../components/MyRssSourceCategory.svelte"
  import RssSourcesByCategoriesMenu from "../components/RssSourcesByCategoriesMenu.svelte"

  import type { RssSourceCategory } from "../models/RssSourceCategory"
  import {
    changeRssSourceCategoryLoading,
    loadRssSourcesByCategories,
    loadRssSourcesCategoriesLoading,
    loadRssSourcesLoading,
    rssSourcesByCategories,
    rssSourcesCategoriesStore,
    rssSourcesStore,
    unfollowRssSourceLoading
  } from "../stores/RssSourcesStore"

  onMount(loadRssSourcesByCategories)

  let addCategory: boolean = false
  let editCategory: RssSourceCategory | undefined = undefined
</script>

<Header>
  <div>
    <button class="primary" on:click={() => (addCategory = true)}>{$_("add.rss.source.category")}</button>
  </div>
</Header>
<SideMenu href="/rss/feeds">
  <div class="menu-items">
    <MenuItem label={$_("findNewRssSources")} href="/rss/sources/find" />
    <MenuItem selected label={$_("myRssSources")} href="/rss/sources" />
    <MenuItem label={$_("favorites")} href="/rss/feeds/liked" />
    <MenuItem
      label={$_("allCategories")}
      href="/rss/feeds"
      count={$rssSourcesStore.reduce((acc, i) => acc + i.unreaded, 0)}
    />
  </div>
  <div class="categories-menu">
    <RssSourcesByCategoriesMenu />
  </div>
</SideMenu>

<Main>
  <div class="rss-sources">
    {#if $loadRssSourcesLoading || $loadRssSourcesCategoriesLoading}
      <Loader size={40} />
    {:else}
      {#each $rssSourcesByCategories as rssSourcesByCategory}
        <MyRssSourceCategory
          rssSourceCategory={rssSourcesByCategory.rssSourceCategory}
          on:edit={e => (editCategory = e.detail.rssSourceCategory)}
        />
        {#each rssSourcesByCategory.rssSources as rssSource}
          <MyRssSource
            rssSourcesCategories={$rssSourcesCategoriesStore}
            {rssSource}
            unfollowRssSourceLoading={$unfollowRssSourceLoading === rssSource.uuid}
            changeRssSourceCategoryLoading={$changeRssSourceCategoryLoading}
            on:unfollow={({ detail }) => rssSourcesStore.unfollowRssSource(detail)}
            on:change_category={({ detail }) =>
              rssSourcesStore.changeRssSourceCategory(detail.rssSource, detail.rssSourceCategory)}
          />
        {/each}
      {/each}
    {/if}
  </div>
</Main>

{#if addCategory}
  <Modal title={$_("add.rss.source.category")} on:close={() => (addCategory = false)}>
    <AddRssSourceCategoryModal
      on:created={e => {
        rssSourcesCategoriesStore.addItem(e.detail.rssSourceCategory)
        addCategory = false
      }}
    />
  </Modal>
{/if}

{#if editCategory}
  <Modal title={$_("edit.rss.source.category")} on:close={() => (editCategory = undefined)}>
    <EditRssSourceCategoryModal
      rssSourceCategory={editCategory}
      on:updated={e => {
        rssSourcesCategoriesStore.updateItem(e.detail.rssSourceCategory)
        editCategory = undefined
      }}
      on:deleted={e => {
        rssSourcesCategoriesStore.removeItem(e.detail.rssSourceCategory)
        editCategory = undefined
      }}
    />
  </Modal>
{/if}

<style>
  .rss-sources {
    max-width: var(--max-width);
    margin: auto;
  }

  .menu-items {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border);
  }

  .categories-menu {
    flex: 1;
    overflow-y: auto;
  }
</style>
