<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { fade } from "svelte/transition"

  import ArrowLeftIcon from "virtual:icons/mdi/arrow-left"
  import ArrowRightIcon from "virtual:icons/mdi/arrow-right"
  import CloseIcon from "virtual:icons/mdi/close"

  import Image from "../components/Image.svelte"

  import { key_action } from "../actions/key_action"
  import Loader from "./Loader.svelte"

  export let ids: string[]

  export let getSrc: (id: string) => string

  export let alt: string
  export let selected: string | undefined = ids[0]

  const dispatch = createEventDispatcher<{
    close: undefined
  }>()

  function handleNext() {
    const index = ids.findIndex(s => s === selected)
    if (index >= 0 && index < ids.length - 1) {
      selected = ids[index + 1]
    } else {
      selected = ids[0]
    }
  }

  function handlePrevious() {
    const index = ids.findIndex(s => s === selected)
    if (index < 1) {
      selected = ids[ids.length - 1]
    } else {
      selected = ids[index - 1]
    }
  }
</script>

<div
  class="images-gallery"
  transition:fade={{ duration: 150 }}
  use:key_action={{ "ArrowRight|d|D": handleNext, "ArrowLeft|q|Q": handlePrevious, Escape: () => dispatch("close") }}
>
  <div class="images">
    <div class="close">
      <button class="icon button" on:click={() => dispatch("close")}>
        <CloseIcon width={30} height={30} />
      </button>
    </div>
    <div class="next">
      <button class="icon button" on:click={handleNext}>
        <ArrowRightIcon width={30} height={30} />
      </button>
    </div>
    <div class="previous">
      <button class="icon button" on:click={handlePrevious}>
        <ArrowLeftIcon width={30} height={30} />
      </button>
    </div>

    {#each ids as id}
      <div class="image" class:selected={id === selected}>
        <Image
          src={getSrc(id)}
          {alt}
          --min-width="50px"
          --min-height="50px"
          --max-width="100%"
          --max-height="calc(100vh - 32px)"
          --bg="#000"
        >
          <Loader color="#FFF" size={40} />
        </Image>
      </div>
    {/each}
  </div>
</div>

<style>
  .images-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    z-index: 1000;
    overflow-x: hidden;
  }

  .image {
    display: none;
    margin-left: var(--s3);
    margin-right: var(--s3);
  }

  .selected {
    display: block;
  }

  .images {
    display: flex;
    flex-direction: row;
  }

  .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #ffffff50;
  }

  .next {
    position: fixed;
    top: calc(100vh / 2);
    right: 15px;
    color: #ffffff50;
  }

  .previous {
    position: fixed;
    top: calc(100vh / 2);
    left: 15px;
    color: #ffffff50;
  }

  .previous:hover,
  .next:hover,
  .close:hover {
    color: #ffffff;
  }

  .button {
    color: #ffffff;
    padding: var(--s1);
  }
  .button:hover {
    color: #ffffff;
    border-color: #ffffff;
  }
</style>
