<script lang="ts">
  import { _ } from "svelte-i18n"

  import Markdown from "../../components/Markdown.svelte"

  import type { TreeNode } from "../../utils"
  import type { Objective } from "../Objective"
  import DocumentationTitle from "./DocumentationTitle.svelte"

  export let node: TreeNode<Objective>
</script>

<div class="node" id={node.data.uuid}>
  <DocumentationTitle objective={node.data}>
    {#if (node.data.depth || 0) === 0}
      <h1 id={node.data.uuid}>{node.data.data.name}</h1>
    {:else if node.data.depth === 1}
      <h2 id={node.data.uuid}>{node.data.data.name}</h2>
    {:else if node.data.depth === 2}
      <h3 id={node.data.uuid}>{node.data.data.name}</h3>
    {:else if node.data.depth === 3}
      <h4 id={node.data.uuid}>{node.data.data.name}</h4>
    {:else if node.data.depth === 4}
      <h5 id={node.data.uuid}>{node.data.data.name}</h5>
    {:else}
      <h6 id={node.data.uuid}>{node.data.data.name}</h6>
    {/if}
  </DocumentationTitle>
  <Markdown source={node.data.data.description || ""} />
</div>

{#each node.children as n (n.data.id)}
  <svelte:self node={n} />
{/each}

<style>
</style>
