<script lang="ts">
  export let label: string
</script>

<span>{label}</span>

<style>
  span {
    border-radius: 3px;
    padding: 2px 7px;
    background-color: var(--bg-color, var(--bg));
    color: var(--color, var(--text-unimportant-color));
    font-size: var(--fs, 0.9rem);
    margin-left: var(--ml, 0);
    margin-right: var(--mr, 0);
  }
</style>
