import { derived, writable } from "svelte/store"

import { Api } from "../../services/Api"
import type { ApiErrors } from "../../services/ApiError"
import { createArrayWritableStore, stratify } from "../../utils"
import type { Objective, ObjectiveState, ObjectiveType, PatchObjective } from "../Objective"

function createObjectivesStore() {
  const store = createArrayWritableStore<Objective>([], b => b.uuid)
  return {
    ...store,
    loadObjectives,
    move,
    changeState,
    patchObjective,
    deleteObjective,
    transformObjective
  }
}

export const objectivesStore = createObjectivesStore()
export const objectivesAncestors = createArrayWritableStore<Objective>([], b => b.uuid)
export const parentStore = writable<Objective | undefined>()

export const objectivesTree = derived([objectivesStore, parentStore], ([objectives, parent]) =>
  parent ? stratify([parent, ...objectives]) : stratify(objectives)
)

export const objectivesError = writable<ApiErrors | undefined>(undefined)
export const loadObjectivesLoading = writable(false)

function loadObjectives(parentUuid: string, types?: ObjectiveType[], depth?: number) {
  objectivesError.set(undefined)
  loadObjectivesLoading.set(true)
  return Api.objectives
    .getObjectives(parentUuid, types, depth)
    .then(result => {
      parentStore.set(result.objective)
      objectivesStore.set(result.childrens)
      objectivesAncestors.set(result.ancestors)
    })
    .catch(objectivesError.set)
    .finally(() => loadObjectivesLoading.set(false))
}

export function loadObjectiveChildren(parentUuid: string, types?: ObjectiveType[], depth?: number) {
  return Api.objectives
    .getObjectives(parentUuid, types, depth)
    .then(result =>
      objectivesStore.update(state => state.concat(result.childrens.filter(o => o.uuid !== result.objective.uuid)))
    )
}

export const movingLoadingUuid = writable<string | undefined>(undefined)
function move(source: Objective, destination: Objective) {
  movingLoadingUuid.set(source.uuid)
  Api.objectives
    .moveObjective({
      source_uuid: source.uuid,
      destination_uuid: destination.uuid
    })
    .then(objectivesStore.updateItem)
    .finally(() => movingLoadingUuid.set(undefined))
}

export const changeStatusLoadingUuid = writable<string | undefined>(undefined)
function changeState(objective: Objective, state: ObjectiveState) {
  changeStatusLoadingUuid.set(objective.uuid)
  Api.objectives
    .patchObjective(objective.uuid, {
      state: {
        type: "Replace",
        value: state
      }
    })
    .then(objectivesStore.updateItem)
    .finally(() => changeStatusLoadingUuid.set(undefined))
}

export const saveObjectveLoading = writable(false)
function patchObjective(objective: Objective, patchObjective: PatchObjective) {
  saveObjectveLoading.set(true)
  return Api.objectives
    .patchObjective(objective.uuid, patchObjective)
    .then(obj => {
      parentStore.set(obj)
      objectivesAncestors.updateItem(obj)
    })
    .finally(() => saveObjectveLoading.set(false))
}

export const transformObjectveLoading = writable(false)
function transformObjective(objective: Objective, kind: ObjectiveType) {
  transformObjectveLoading.set(true)
  return Api.objectives
    .transformObjective(objective.uuid, kind)
    .then(obj => {
      parentStore.set(obj)
      objectivesAncestors.updateItem(obj)
    })
    .finally(() => transformObjectveLoading.set(false))
}

export const deleteObjectiveLoadingUuid = writable<string | undefined>(undefined)
function deleteObjective(objective: Objective) {
  deleteObjectiveLoadingUuid.set(objective.uuid)
  Api.objectives
    .deleteObjective(objective)
    .then(() => objectivesStore.removeItem(objective))
    .finally(() => deleteObjectiveLoadingUuid.set(undefined))
}

export const wantMoveObjective = writable<Objective | undefined>(undefined)
export const wantEditObjective = writable(false)
