import { _, addMessages, getLocaleFromNavigator, init } from "svelte-i18n"

const fr = {
  title: "Mindstream",
  // commun actions
  add: "Ajouter",
  accept: "Accepter",
  create: "Créer",
  close: "Fermer",
  cancel: "Annuler",
  delete: "Supprimer",
  decline: "Décliner",
  move: "Déplacer",
  "move.here": "Déplacer ici",
  save: "Sauvegarder",
  upload: "Upload",
  download: "Télécharger",
  cleanup: "Vider",
  search: "Chercher",
  edit: "Modifier",
  refresh: "Rafraîchir",
  rename: "Renommer",
  select: "Sélectionner",
  previous: "Précédent",
  next: "Suivant",
  more: "Plus",
  back: "Retour",
  logout: "Logout",
  seeMore: "Voir plus",
  selected: "{nb} sélectionné",
  multiselect: "Sélection multiple",
  description: "Description",
  documentation: "Documentation",
  members: "Membres",
  "project.members": "Membres du projet ",
  "invite.member": "Inviter un membre",
  invite: "Inviter",
  auth: {
    login: "S'identifier",
    signup: "S'inscrire",
    email: "Email",
    password: "Mot de passe",
    username: "Nom"
  },
  Bug: "Bug",
  Task: "Tâche",
  Story: "Story",
  ProjectRoot: "Projet",
  projects: "Projets",
  addProject: "Add project",
  "bad.request": "Erreurs de validations",
  unauthorized: "Email ou mot de passe incorrect",
  validation: {
    email: "Email invalide",
    "login.length": "Login doit être entre {min} et {max} caractères",
    "password.short": "Mot de passe doit être supérieure à {min}",
    error: "Erreurs de validations",
    url: "Url invalide",
    required: "Requis"
  },
  objectives: "Objectifs",
  confirmDelete: "Est-vous sûr de vouloir supprimer",
  bin: "Corbeille",
  empty: "Vide",
  menu: "Menu",
  createDirectory: "Créer un dossier",
  createNote: "Créer une note",
  account: "Compte",
  bookmarks: "Bookmarks",
  categories: "Catégories",
  "my.categories": "Mes catégories",
  allCategories: "Toutes les catégories",
  withoutCategories: "Sans catégorie",
  archive: "Archiver",
  unarchive: "Désarchiver",
  archived: "Archivé",
  myRssSources: "Mes flux RSS",
  findNewRssSources: "Trouver des flux RSS",
  favorites: "Favoris",
  created: "Ajouté le {date}",
  noMoreRssFeeds: "Il n'y a plus de flux RSS",
  noResult: "Aucun résultat trouvé",
  noRssFeeds: "Aucun flux Rss",
  "no.category": "Pas de catégorie",
  "no.categories": "Pas de catégories",
  "rss.feeds": "Flux RSS",
  "add.bookamrk": "Ajouter un bookmark",
  "add.rss.source.category": "Ajouter une categorie",
  "edit.rss.source.category": "Modifier une categorie",
  "menu.profile": "Profile",
  "menu.files.store": "Fichiers",
  "form.url": "Url",
  "menu.markAsReadOneMonth": "Marquer comme lu : Un mois",
  "menu.markAsReadOneWeek": "Marquer comme lu : Une semaine",
  "menu.markAsReadOneDay": "Marquer comme lu : Un jour",
  "menu.markAsReadAll": "Marquer comme lu : Tout",
  "form.title": "Titre",
  "form.email": "Email",
  "form.type": "Type",
  "form.password": "Mot de passe",
  "form.old.password": "Ancien mot de passe",
  "form.new.password": "Nouveau mot de passe",
  "form.changePassword": "Changer le mot de passe",
  "form.updateProfile": "Modifier le profile",
  "title.change.password": "Changement de mot de passe",
  "title.update.profile": "Modification du profile",
  tableOfContent: "Table des matières",
  cost: "Coût",
  value: "Valeur",
  "not.found": "Aucun résultat",
  "add.member": "Ajouter un membre",
  "member.invitation": "Vous êtes invité à rejoindre le projet",
  "no.projects": "Vous n'avez aucun projet",
  "create.new.project": "Créer un nouveau projet ",
  "already.exists": "Existe déjà",
  elements: "éléments",
  "note.title": "Titre",
  "bookmarks.folder.name": "Nom du dossier",
  "bookmarks.empty": "Aucun bookmarks sauvegardé",
  "bookmarks.archived.empty": "Aucun bookmarks archivé",
  "bookmarks.add": "Ajouter un bookmark",
  "bookmarks.add.url": "Ajouter un lien",
  "bookmarks.add.category": "Ajouter une categorie",
  "bookmarks.add.folder": "Ajouter un dossier",
  // Objectives
  "objectives.add.project": "Ajouter un projet",
  // RSS
  "rss.liked.remove": "Enlever",
  "rss.liked.title": "Favoris",
  "rss.liked.save.bookmark": "Ajouter aux bookmarks",
  "rss.liked.empty": "Aucun RSS favoris",
  "rss.follow": "S'abonner",
  "rss.unfollow": "Ne plus suivre"
}

type Messages = typeof fr

addMessages("fr", fr)

const en: Messages = {
  title: "Mindstream",
  // commun actions
  add: "Add",
  accept: "Accept",
  create: "Create",
  close: "Close",
  cancel: "Cancel",
  delete: "Delete",
  decline: "Decline",
  move: "Move",
  "move.here": "Move here",
  save: "Save",
  upload: "Upload",
  download: "Download",
  cleanup: "Cleanup",
  search: "Search",
  edit: "Edit",
  refresh: "Refresh",
  rename: "Rename",
  select: "Select",
  previous: "Previous",
  next: "Next",
  more: "More",
  back: "Go back",
  logout: "Logout",
  seeMore: "See more",
  selected: "{nb} selected",
  multiselect: "Multiple selection ",
  description: "Description",
  documentation: "Documentation",
  members: "Members",
  "project.members": "Project members",
  "invite.member": "Invite member",
  invite: "Invite",
  auth: {
    login: "Login",
    signup: "Signup",
    email: "Email",
    password: "Password",
    username: "Username"
  },
  Bug: "Bug",
  Task: "Task",
  Story: "Story",
  ProjectRoot: "Project",
  projects: "Projects",
  addProject: "Ajouter un projet ",
  "bad.request": "Incorrect email or password",
  unauthorized: "Unauthorized",
  validation: {
    email: "Invalid email",
    "login.length": "Login should be between {min} and {max} characters",
    "password.short": "Password should be greater than {min}",
    error: "Validations errors",
    url: "Invalid url",
    required: "Required"
  },
  objectives: "Objectives",
  confirmDelete: "Are you sure you want to delete",
  bin: "Bin",
  empty: "Empty",
  menu: "Menu",
  createDirectory: "Create Directory",
  createNote: "Create note",
  account: "Account",
  bookmarks: "Bookmarks",
  categories: "Categories",
  "my.categories": "My Categories",
  allCategories: "All categories",
  withoutCategories: "Without categorie",
  archive: "Archive",
  unarchive: "Unarchive",
  archived: "Archived",
  myRssSources: "My Rss Sources",
  findNewRssSources: "Find new RSS sources",
  favorites: "Favorites",
  created: "Added {date}",
  noMoreRssFeeds: "No more rss feeds",
  noResult: "No result found",
  noRssFeeds: "No Rss feed",
  "no.category": "No category",
  "no.categories": "No categories",
  "rss.feeds": "Rss feeds",
  "add.bookamrk": "Add Bookmark",
  "add.rss.source.category": "Add rss source category",
  "edit.rss.source.category": "Edit rss source category",
  "menu.profile": "Profile",
  "menu.files.store": "Files",
  "menu.markAsReadOneMonth": "Mark as read: One month",
  "menu.markAsReadOneWeek": "Mark as read: One week",
  "menu.markAsReadOneDay": "Mark as read: One day",
  "menu.markAsReadAll": "Mark as read: All",
  "form.url": "Url",
  "form.title": "Title",
  "form.email": "Email",
  "form.type": "Type",
  "form.password": "Password",
  "form.old.password": "Old password",
  "form.new.password": "New password",
  "form.changePassword": "Change password",
  "form.updateProfile": "Update profile",
  "title.change.password": "Password change",
  "title.update.profile": "Profile modification",
  tableOfContent: "Table of content",
  cost: "Cost",
  value: "Value",
  "not.found": "No result",
  "add.member": "Add member",
  "member.invitation": "You are invited to join the project",
  "no.projects": "You have no projects",
  "create.new.project": "Create new project",
  "already.exists": "Already exists",
  elements: "elements",
  "note.title": "Title",
  "bookmarks.folder.name": "Folder name",
  "bookmarks.empty": "No saved bookmarks",
  "bookmarks.archived.empty": "No archived bookmarks",
  "bookmarks.add": "Add bookmark",
  "bookmarks.add.url": "Add a link",
  "bookmarks.add.category": "Add category",
  "bookmarks.add.folder": "Add bookmarks folder",
  // Objectives
  "objectives.add.project": "Add project",
  // RSS
  "rss.liked.remove": "Remove",
  "rss.liked.title": "Favorites",
  "rss.liked.save.bookmark": "Add to bookmarks",
  "rss.liked.empty": "No RSS Favorites",
  "rss.follow": "Follow",
  "rss.unfollow": "Unfollow"
}

addMessages("en", en)

init({
  fallbackLocale: "fr",
  initialLocale: getLocaleFromNavigator()
})
