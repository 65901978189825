<script lang="ts">
  import FileEpubIcon from "virtual:icons/mdi/book-open-page-variant-outline"
  import FileIcon from "virtual:icons/mdi/file-outline"
  import FilePdfIcon from "virtual:icons/mdi/file-pdf-outline"
  import FolderIcon from "virtual:icons/mdi/folder-outline"
  import FileZipIcon from "virtual:icons/mdi/folder-zip-outline"
  import NoteTextIcon from "virtual:icons/mdi/note-text-outline"

  import { FsNode, isFsNodeDirectory, isFsNodeFile, isFsNodeImage, isFsNodeNote, isFsNodePDF } from "../models/FsNode"

  export let fsNode: FsNode
  export let thumbnailUri: string

  export let width: number = 30
  export let height: number = 30
</script>

{#if isFsNodeDirectory(fsNode.data)}
  <FolderIcon color="#245b48" {width} {height} />
{:else if isFsNodeImage(fsNode.data)}
  <img class="thumbnail" src={thumbnailUri} alt="thumbnail" />
{:else if isFsNodeNote(fsNode.data)}
  <NoteTextIcon color="#3F51B5" {width} height={width} />
{:else if isFsNodeFile(fsNode.data) && isFsNodePDF(fsNode.data)}
  <FilePdfIcon color="#EF5350" {width} height={width} />
{:else if isFsNodeFile(fsNode.data) && fsNode.data.content_type === "application/epub+zip"}
  <FileEpubIcon color="#FFA726" {width} height={width} />
{:else if isFsNodeFile(fsNode.data) && fsNode.data.content_type === "application/zip"}
  <FileZipIcon color="#4CAF50" {width} height={width} />
{:else}
  <FileIcon color="#245b48" {width} height={width} />
{/if}

<style>
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>
