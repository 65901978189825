import type { RawAxiosRequestConfig } from "axios"

import { ApiService } from "../services/ApiService"
import type { Login } from "./models/Login"
import type { PasswordAuthResponse } from "./models/PasswordAuthResponse"
import type { PasswordCredentialsPayload } from "./models/PasswordCredentialsPayload"
import type { Signup } from "./models/Signup"

export class AuthService extends ApiService {
  public login(login: Login, scope: string = "rss_feeds files bookmarks objectives"): Promise<PasswordAuthResponse> {
    const passwordCredentialsPayload: PasswordCredentialsPayload = {
      ...login,
      scope,
      grant_type: "password"
    }
    return this.authenticate({ url: "/api/users/login", method: "POST", data: passwordCredentialsPayload })
  }

  public signup(signup: Signup): Promise<PasswordAuthResponse> {
    return this.authenticate({ url: "/api/users/signup", method: "POST", data: signup })
  }

  public logout(): Promise<void> {
    return this.storage
      .removeItem(this.STORAGE_AUTH_TOKEN_KEY)
      .then(() => this.onUnauthorized())
      .catch(() => this.onUnauthorized())
  }

  private authenticate(config: RawAxiosRequestConfig): Promise<PasswordAuthResponse> {
    return this.instance
      .request<PasswordAuthResponse>(config)
      .then(response =>
        this.storage.setItem(this.STORAGE_AUTH_TOKEN_KEY, response.data.access_token).then(() => response.data)
      )
      .catch(error =>
        // tslint:disable-next-line: no-unsafe-any
        Promise.reject(error.response.data)
      )
  }
}
