<script lang="ts">
  import { _ } from "svelte-i18n"

  import type { TreeNode } from "../../utils"
  import type { Objective } from "../Objective"
  import TableOfContentNode from "./TableOfContentNode.svelte"

  export let objectivesTree: TreeNode<Objective> | undefined
</script>

<div class="table-of-content-title">{$_("tableOfContent")}</div>

<ul class="table-of-content">
  {#if objectivesTree}
    <TableOfContentNode node={objectivesTree} />
  {/if}
</ul>

<style>
  .table-of-content-title {
    padding: 16px 16px 10px 16px;
    color: var(--color-light);
  }

  .table-of-content {
    padding: 0 16px;
    margin: 0;
  }
</style>
