<script lang="ts">
  import { fade } from "svelte/transition"

  import { key_action } from "../../actions/key_action"
  import { drawer } from "../../stores/store"
</script>

<main class:open={$drawer}>
  <slot />
  {#if $drawer}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="background"
      on:click={() => drawer.close()}
      use:key_action={{ Escape: () => drawer.close() }}
      transition:fade={{ duration: 150 }}
    />
  {/if}
</main>

<style>
  main {
    min-height: calc(100vh - var(--header-height));
    transition: margin var(--transition-duration) cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  main.open {
    margin-left: var(--side-menu-width);
  }

  .background {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: var(--zi, 100);
  }

  @media screen and (max-width: 768px) {
    .background {
      display: block;
    }
  }
</style>
