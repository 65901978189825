import { writable } from "svelte/store"

import { Api } from "../../services/Api"
import type { ApiErrors } from "../../services/ApiError"
import type { Pagination } from "../../services/Pagination"
import { createArrayWritableStore } from "../../utils"
import type { RssFeed } from "../models/RssFeed"

function createLikedRssFeedsStore() {
  const store = createArrayWritableStore<RssFeed>([], b => b.uuid)
  return {
    ...store,
    loadLikedRssFeeds
  }
}

export const likedRssFeedsStore = createLikedRssFeedsStore()
export const likedRssFeedsError = writable<ApiErrors | undefined>(undefined)

export const loadLikedRssFeedsLoading = writable(false)
function loadLikedRssFeeds(pagination: Pagination) {
  loadLikedRssFeedsLoading.set(true)
  likedRssFeedsError.set(undefined)
  return Api.rssFeeds
    .getRssFeeds("Liked", pagination)
    .then(result => {
      likedRssFeedsStore.set(result)
      loadLikedRssFeedsLoading.set(false)
      return result
    })
    .catch((errors: ApiErrors) => {
      likedRssFeedsError.set(errors)
      loadLikedRssFeedsLoading.set(false)
      return Promise.reject(errors)
    })
}
