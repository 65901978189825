<script lang="ts">
  export let label = ""
</script>

<div class="empty-panel">
  <h1 class="title">
    {label}
  </h1>
  <slot />
</div>

<style>
  .empty-panel {
    padding: var(--s6) var(--s3);
    border: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-1);
  }

  .title {
    font-size: var(--fs1);
    color: var(--color-grey);
  }
</style>
