<script lang="ts">
  import { _ } from "svelte-i18n"

  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import Breadcrumb from "../components/Breadcrumb.svelte"
  import NotePreview from "../components/NotePreview.svelte"

  import { Api } from "../../services/Api"
  import { failure } from "../../toast"
  import { FsNode, isFsNodeNote } from "../models/FsNode"

  export let uuid: string | undefined

  $: filesResponse = Api.fsNodes.getFiles(uuid, "Root")

  function handleEditName(fsNode: FsNode, value: string) {
    return Api.fsNodes
      .patchFsNode(fsNode.uuid, { name: { type: "Replace", value } })
      .catch(error => failure($_(error.message)))
  }

  function handleEditContent(fsNode: FsNode, value: string) {
    return Api.fsNodes
      .patchFsNode(fsNode.uuid, { content: { type: "Replace", value } })
      .catch(error => failure($_(error.message)))
  }
</script>

<Header />

<SideMenu href="/fs">
  <MenuItem label={$_("bin")} href="/fs/trash-bin" />
</SideMenu>

<Main>
  {#await filesResponse}
    <Loader size={40} />
  {:then files}
    <div class="tools">
      <Breadcrumb ancestors={files.ancestors} />
    </div>
    {#if isFsNodeNote(files.fs_node.data)}
      <NotePreview
        note={files.fs_node.data}
        on:edit_name={({ detail }) => handleEditName(files.fs_node, detail)}
        on:edit_content={({ detail }) => handleEditContent(files.fs_node, detail)}
      />
    {:else}
      <p>Not a note</p>
    {/if}
  {:catch error}
    <p style="color: red">{error.message}</p>
  {/await}
</Main>

<style>
  .tools {
    border-bottom: 1px solid var(--border);
  }
</style>
