import { writable } from "svelte/store"

import { Api } from "../../services/Api"
import type { ApiErrors } from "../../services/ApiError"
import { createArrayWritableStore } from "../../utils"
import type { Member } from "../Member"

function createMembersStore() {
  const store = createArrayWritableStore<Member>([], b => b.uuid)
  return {
    ...store,
    loadMembers,
    inviteMember
  }
}

export const membersStore = createMembersStore()
export const membersError = writable<ApiErrors | undefined>(undefined)
export const loadMembersLoading = writable(false)

function loadMembers(uuid: string) {
  membersError.set(undefined)
  loadMembersLoading.set(true)
  return Api.objectives
    .getMembers(uuid)
    .then(membersStore.set)
    .catch(membersError.set)
    .finally(() => loadMembersLoading.set(false))
}

export const wantInviteMember = writable(false)

export const inviteMemberError = writable<ApiErrors | undefined>(undefined)
export const inviteMemberLoading = writable(false)

function inviteMember(projectUuid: string, email: string) {
  inviteMemberError.set(undefined)
  inviteMemberLoading.set(true)
  return Api.objectives
    .inviteMember(projectUuid, email)
    .then(newMember => {
      membersStore.addItem(newMember)
    })
    .catch((errors: ApiErrors) => inviteMemberError.set(errors))
    .finally(() => inviteMemberLoading.set(false))
}

function createProjectInvitationsStore() {
  const store = createArrayWritableStore<Member>([], b => b.uuid)
  return {
    ...store,
    loadProjectInvitations,
    acceptInvitation,
    declineInvitation
  }
}
export const projectInvitationsStore = createProjectInvitationsStore()

export const loadProjectInvitationsError = writable<ApiErrors | undefined>(undefined)
export const loadProjectInvitationsLoading = writable(false)
function loadProjectInvitations() {
  loadProjectInvitationsError.set(undefined)
  loadProjectInvitationsLoading.set(true)
  return Api.objectives
    .projectInvitations()
    .then(projectInvitationsStore.set)
    .catch((errors: ApiErrors) => loadProjectInvitationsError.set(errors))
    .finally(() => loadProjectInvitationsLoading.set(false))
}

export const acceptInvitationError = writable<ApiErrors | undefined>(undefined)
export const acceptInvitationLoading = writable<string | undefined>(undefined)
function acceptInvitation(projectUuid: string) {
  acceptInvitationError.set(undefined)
  acceptInvitationLoading.set(projectUuid)
  return Api.objectives
    .invitationResponse(projectUuid, "Accepted")
    .then(member => {
      projectInvitationsStore.update(members => members.filter(m => m.uuid !== member.uuid))
    })
    .catch((errors: ApiErrors) => acceptInvitationError.set(errors))
    .finally(() => acceptInvitationLoading.set(undefined))
}

export const declineInvitationError = writable<ApiErrors | undefined>(undefined)
export const declineInvitationLoading = writable<string | undefined>(undefined)
function declineInvitation(projectUuid: string) {
  declineInvitationError.set(undefined)
  declineInvitationLoading.set(projectUuid)
  return Api.objectives
    .invitationResponse(projectUuid, "Declined")
    .then(member => {
      projectInvitationsStore.update(members => members.filter(m => m.uuid !== member.uuid))
    })
    .catch((errors: ApiErrors) => declineInvitationError.set(errors))
    .finally(() => declineInvitationLoading.set(undefined))
}
