import { array, boolean, number, object, string } from "idonttrustlikethat"

import { indefinable } from "../../utils"

export const rssSourceValidator = object({
  uuid: string,
  url: string,
  title: string,
  website: string,
  description: indefinable(string),
  language: indefinable(string),
  icon_url: indefinable(string),
  cover_url: indefinable(string),
  visual_url: indefinable(string),
  topics: indefinable(array(string)),
  last_updated: indefinable(string),
  error: indefinable(string),
  created_at: string,
  updated_at: string,
  unreaded: number,
  rss_source_category_uuid: indefinable(string),
  is_follow: boolean
})
export const rssSourcesValidator = array(rssSourceValidator)
export type RssSource = typeof rssSourceValidator.T

export function getRssSourceIconUrl({ visual_url, icon_url, cover_url }: RssSource): string | undefined {
  if (visual_url) {
    return visual_url
  } else if (icon_url) {
    return icon_url
  } else if (cover_url) {
    return cover_url
  } else {
    return undefined
  }
}

export function getUnreaded({ unreaded }: RssSource): number {
  return positive(unreaded)
}

export function positive(n: number): number {
  return n >= 0 ? n : 0
}
