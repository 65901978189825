<script lang="ts">
  import { createEventDispatcher } from "svelte"

  import TaskIcon from "virtual:icons/clarity/tasks-line"
  import BugIcon from "virtual:icons/mdi/bug-outline"
  import TasksIcon from "virtual:icons/mdi/file-tree"

  import type { Objective } from "../Objective"

  export let ancestors: Objective[]

  const dispatch = createEventDispatcher<{
    click: Objective
  }>()
</script>

<div class="breadcrumb">
  {#each ancestors as ancestor (ancestor.uuid)}
    {#if ancestor.data.type !== "ProjectRoot"}
      <div class="separaton">/</div>
    {/if}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="crumb" on:click={() => dispatch("click", ancestor)}>
      {#if ancestor.data.type === "Story" || ancestor.data.type === "ProjectRoot"}
        <TasksIcon color="#245b48" />
      {:else if ancestor.data.type === "Bug"}
        <BugIcon color="#931a1a" />
      {:else if ancestor.data.type === "Task"}
        <TaskIcon color="#d2cd53" />
      {/if}
      {ancestor.data.name}
    </div>
  {/each}
</div>

<style>
  .breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .separaton {
    color: var(--text-primary-color);
    margin-left: 5px;
    margin-right: 5px;
  }

  .crumb {
    color: var(--text-primary-color);
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  .crumb:hover {
    text-decoration: underline;
  }
</style>
