<script lang="ts">
  import { _ } from "svelte-i18n"

  import CloseIcon from "virtual:icons/mdi/close"

  import { clear, uploadFiles } from "../stores/UploadStore"
</script>

<div class="upload-panel">
  <div class="header">
    <div class="title">{$_("upload")}</div>
    <button class="icon" on:click={clear}>
      <CloseIcon />
    </button>
  </div>
  {#each $uploadFiles as uploadfile}
    <div class="upload-file">
      <div class="name">{uploadfile.name} - {uploadfile.progress}%</div>
    </div>
  {/each}
</div>

<style>
  .upload-panel {
    border-top: 1px solid var(--border);
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
  }

  .upload-file {
    padding: 5px 0;
    border-bottom: 1px solid var(--border);
  }

  .name {
    font-size: 0.8rem;
  }
</style>
