import { navigate } from "svelte-navigator"

import { AuthService } from "../account/AuthService"
import { API_HOST } from "../conf"
import { FsNodesService } from "../files_store/FsNodesService"
import { ObjectivesService } from "../objectives/ObjectivesService"
import { RssFeedsService } from "../rss_feeds/RssFeedsService"
import { RssSourceCategoryService } from "../rss_feeds/RssSourceCategoryService"
import { RssSourcesService } from "../rss_feeds/RssSourcesService"
import type { ServiceConfig } from "./ApiService"
import type { ApiStorage } from "./ApiStorage"

const storage: ApiStorage = {
  getItemSync: key => localStorage.getItem(key),
  getItem: key => Promise.resolve(localStorage.getItem(key)),
  setItem: (key, value) => Promise.resolve(localStorage.setItem(key, value)),
  removeItem: key => Promise.resolve(localStorage.removeItem(key)),
  clear: () => Promise.resolve(localStorage.clear())
}

function onUnauthorized() {
  storage
    .clear()
    .then(() => {})
    .catch(() => {})
  navigate("/login", { replace: true })
}

const config: ServiceConfig = { onUnauthorized, storage, baseURL: API_HOST }

export const Api = {
  auth: new AuthService(config),
  fsNodes: new FsNodesService(config),
  rssSources: new RssSourcesService(config),
  rssFeeds: new RssFeedsService(config),
  rssSourcesCategories: new RssSourceCategoryService(config),
  objectives: new ObjectivesService(config)
}
