import App from "./App.svelte"
import "./i18n/i18n"
import "./theme.css"
import "./theme/buttons.css"
import "./theme/inputs.css"

var app = new App({
  target: document.body
})

export default app

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/concepts/hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept()
  import.meta.hot.dispose(() => {
    app.$destroy()
  })
}
