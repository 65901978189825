<script lang="ts">
  import { subDays, subMonths, subWeeks } from "date-fns"

  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import MoreIcon from "virtual:icons/mdi/dots-vertical"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"

  import type { RssSource } from "../models/RssSource"

  export let loading: boolean = false
  export let currentRssSource: RssSource

  const dispatch = createEventDispatcher<{
    mark_as_readed: {
      date: Date
      currentRssSource: RssSource
    }
  }>()

  function handleMarkAsReaded(date: Date) {
    dispatch("mark_as_readed", { date, currentRssSource })
  }
</script>

<Dropdown right>
  <svelte:fragment slot="action">
    <button class="icon" aria-busy={loading}>
      <MoreIcon />
    </button>
  </svelte:fragment>
  <svelte:fragment slot="items">
    <DropdownItem label={$_("menu.markAsReadOneDay")} on:click={() => handleMarkAsReaded(subDays(new Date(), 1))} />
    <DropdownItem label={$_("menu.markAsReadOneWeek")} on:click={() => handleMarkAsReaded(subWeeks(new Date(), 1))} />
    <DropdownItem label={$_("menu.markAsReadOneMonth")} on:click={() => handleMarkAsReaded(subMonths(new Date(), 1))} />
    <DropdownItem label={$_("menu.markAsReadAll")} on:click={() => handleMarkAsReaded(new Date())} />
  </svelte:fragment>
</Dropdown>
