<script lang="ts">
  import { link } from "svelte-navigator"

  import DeleteIcon from "virtual:icons/mdi/delete-outline"
  import HomeIcon from "virtual:icons/mdi/home-outline"

  import { FsNode, getFsNodeName, isFsNodeBin, isFsNodeRoot } from "../models/FsNode"

  export let ancestors: FsNode[]
</script>

<div class="breadcrumb">
  {#each ancestors as ancestor (ancestor.uuid)}
    {#if isFsNodeRoot(ancestor.data)}
      <a use:link class="root" href="/fs">
        <HomeIcon width={24} height={24} color="#333333" />
      </a>
    {:else if isFsNodeBin(ancestor.data)}
      <a use:link href="/fs/trash-bin">
        <DeleteIcon width={24} height={24} color="#333333" />
      </a>
    {:else}
      <div class="separaton">/</div>
      {#if getFsNodeName(ancestor.data)}
        <a use:link class="crumb" href="/fs/{ancestor.uuid}">{getFsNodeName(ancestor.data)}</a>
      {/if}
    {/if}
  {/each}
</div>

<style>
  .breadcrumb {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .separaton {
    color: var(--text-primary-color);
    margin-left: 5px;
    margin-right: 5px;
  }

  .crumb {
    color: var(--text-primary-color);
    text-decoration: none;
  }

  .crumb:hover {
    text-decoration: underline;
  }

  .root:hover {
    opacity: 0.8;
  }
</style>
