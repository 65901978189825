import type { CancelTokenSource } from "axios"

import type { Reaction, RssFeed } from "../rss_feeds/models/RssFeed"
import { rssFeedValidator, rssFeedsValidator } from "../rss_feeds/models/RssFeed"
import { ApiService } from "../services/ApiService"
import type { Pagination } from "../services/Pagination"
import { queryString } from "../utils"

export class RssFeedsService extends ApiService {
  public getRssFeeds(
    reaction: Reaction,
    pagination: Pagination,
    rssSourceUuid?: string,
    rssSourceCategoryUuid?: string,
    cancelToken?: CancelTokenSource
  ): Promise<RssFeed[]> {
    return this.typedRequest(
      {
        url: `/api/rss/feeds${queryString({
          ...pagination,
          reaction,
          rss_source_uuid: rssSourceUuid,
          rss_source_category_uuid: rssSourceCategoryUuid
        })}`,
        method: "GET",
        cancelToken: cancelToken?.token
      },
      rssFeedsValidator
    )
  }

  public getRssFeed(rssUuid: string, cancelToken?: CancelTokenSource): Promise<RssFeed> {
    return this.typedRequest(
      {
        url: `/api/rss/feeds/${rssUuid}`,
        method: "GET",
        cancelToken: cancelToken?.token
      },
      rssFeedValidator
    )
  }

  public updateRssFeed(rssFeed: RssFeed, reaction: Reaction, cancelToken?: CancelTokenSource): Promise<void> {
    return this.request({
      url: `/api/rss/feeds/${rssFeed.uuid}`,
      method: "PUT",
      data: { reaction },
      cancelToken: cancelToken?.token
    })
  }
}
