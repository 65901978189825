<script lang="ts">
  import { createEventDispatcher } from "svelte"

  export let checked: boolean
  const dispatch = createEventDispatcher<{ change: boolean }>()
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="checkbox" class:checked on:click={() => dispatch("change", !checked)} />

<style>
  .checkbox {
    width: 17px;
    height: 17px;
    background-color: #ccc;
    border-radius: 2px;
    cursor: pointer;
    transition-duration: var(--transition-duration);
  }

  .checkbox:hover {
    background-color: #999;
  }

  .checked {
    position: relative;
    background-color: var(--bg-accent-color);
  }

  .checked:hover {
    background-color: #777;
  }

  .checked:after {
    content: "";
    position: absolute;
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 1px;
  }
</style>
