<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import EditIcon from "virtual:icons/mdi/square-edit-outline"

  import type { RssSourceCategory } from "../models/RssSourceCategory"

  export let rssSourceCategory: RssSourceCategory

  const dispatch = createEventDispatcher<{
    edit: {
      rssSourceCategory: RssSourceCategory
    }
  }>()
</script>

<div class="category">
  <div class="title">{rssSourceCategory.title}</div>
  <button class="icon" on:click={() => dispatch("edit", { rssSourceCategory })}>
    <EditIcon />
  </button>
</div>

<style>
  .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid var(--border);
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }
</style>
