/**
 * Key actions
 *
 * ArrowRight, ArrowLeft, d|D, q|Q
 *
 * Enter, Escape
 *
 * Usage use:key_action="{{ "ArrowRight|d|D": handle }}"
 */
export function key_action(_: HTMLElement, props: Record<string, () => void>) {
  function keydown(event: KeyboardEvent) {
    for (const key of Object.keys(props)) {
      if (key.split("|").includes(event.key)) {
        props[key]()
      }
    }
  }

  window.addEventListener("keydown", keydown)

  return {
    destroy() {
      window.removeEventListener("keydown", keydown)
    }
  }
}
