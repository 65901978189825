<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { getFieldErrorMessage } from "../../utils"
  import type { Objective } from "../Objective"

  let name = ""
  let description = ""
  let loading: boolean = false
  let requestErrors: ApiErrors | undefined = undefined
  $: nameError = getFieldErrorMessage("name", (k, values) => $_(k, { values }), requestErrors)

  const dispatch = createEventDispatcher<{
    added: Objective
  }>()

  function submit() {
    loading = true
    Api.objectives
      .createRootObjective({
        name,
        description
      })
      .then(result => dispatch("added", result))
      .catch((errors: ApiErrors) => (requestErrors = errors))
      .finally(() => (loading = false))
  }
</script>

<div class="modal">
  <label for="title">
    {$_("form.title")}
    <input id="title" type="text" bind:value={name} />
    {#if nameError}
      <div class="error">{nameError}</div>
    {/if}
  </label>
  <div class="form-field">
    <label for="description">
      {$_("description")}
      <textarea name="description" bind:value={description} />
    </label>
  </div>
  {#if requestErrors}
    <div class="error">{$_(requestErrors.message)}</div>
  {/if}
  <div>
    <button class="primary" aria-busy={loading} on:click={submit}>{$_("add")}</button>
  </div>
</div>

<style>
  .modal {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
    padding: 0 var(--s3) var(--s3) var(--s3);
  }

  .error {
    color: var(--text-negative-color);
  }
</style>
