<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"
  import { link } from "svelte-navigator"

  import TaskIcon from "virtual:icons/clarity/tasks-line"
  import BugIcon from "virtual:icons/mdi/bug-outline"
  import ChecIcon from "virtual:icons/mdi/check"
  import ChevronDownIcon from "virtual:icons/mdi/chevron-down"
  import ChevronRightIcon from "virtual:icons/mdi/chevron-right"
  import CircleIcon from "virtual:icons/mdi/circle-outline"
  import DeleteForeverIcon from "virtual:icons/mdi/delete-forever-outline"
  import MoreIcon from "virtual:icons/mdi/dots-vertical"
  import AddIcon from "virtual:icons/mdi/plus"
  import MoveIcon from "virtual:icons/ri/drag-drop-line"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"
  import Loader from "../../components/Loader.svelte"
  import ProgressBar from "../../components/ProgressBar.svelte"

  import Badge from "../../ui_kit/Badge.svelte"
  import type { TreeNode } from "../../utils"
  import type { Objective } from "../Objective"
  // TODO remove this dependency
  import { loadObjectiveChildren } from "../stores/ObjectivesStore"

  export let node: TreeNode<Objective>
  export let topLevel: boolean = false
  export let draged = false
  export let dragedId: string | undefined = undefined
  export let dragedToId: string | undefined = undefined
  export let expanded = node.data.data.has_children && node.children.length > 0
  export let changeStatusLoadingUuid: string | undefined = undefined
  export let movingLoadingUuid: string | undefined = undefined
  export let moreActionLoadingUuid: string | undefined = undefined

  let loadingChildren = false

  function toggle() {
    if (!expanded && node.data.data.has_children && node.children.length === 0) {
      loadingChildren = true
      // TODO Find a better place for this call, because it make this component dependent on store
      // TODO Handle error
      loadObjectiveChildren(node.data.uuid, undefined, 1).finally(() => (loadingChildren = false))
    }
    expanded = !expanded
  }

  const dispatch = createEventDispatcher<{
    add: Objective
    changeState: Objective
    delete: Objective
    move: Objective
  }>()

  let canDrop = draged && dragedId !== dragedToId

  $: percent = Math.floor(((node.data.data.completed || 0) * 100) / (node.data.data.cost || 1)) || 0
</script>

<div
  class:draged
  class:canDrop
  class="node"
  id={node.data.uuid}
  class:topLevel={!topLevel}
  draggable="true"
  on:drop
  on:dragover
  on:dragleave
  on:dragstart
  on:dragend
  on:dragenter
>
  {#if node.data.data.type === "Story" || node.data.data.type === "ProjectRoot"}
    <div class="chevron">
      <button class="icon" on:click={toggle} aria-busy={loadingChildren}>
        {#if expanded}
          <ChevronDownIcon />
        {:else}
          <ChevronRightIcon />
        {/if}
      </button>
    </div>
  {/if}
  {#if node.data.data.type !== "Story" && node.data.data.type !== "ProjectRoot"}
    <div class="status">
      <button
        class="icon"
        on:click={() => dispatch("changeState", node.data)}
        aria-busy={changeStatusLoadingUuid === node.data.uuid}
      >
        {#if node.data.data.state === "Completed"}
          <ChecIcon />
        {:else}
          <CircleIcon />
        {/if}
      </button>
    </div>
  {/if}
  <div class="content">
    <div class="node-icon">
      {#if movingLoadingUuid === node.data.uuid}
        <Loader size={30} />
      {:else if node.data.data.type === "Story"}
        <ProgressBar {percent} />
      {:else if node.data.data.type === "Bug"}
        <BugIcon width={30} height={30} color="#931a1a" />
      {:else if node.data.data.type === "Task"}
        <TaskIcon width={30} height={30} color="#d2cd53" />
      {/if}
    </div>
    <div class="title">
      <a use:link href={`/objectives/${node.data.uuid}`} draggable="false">{node.data.data.name}</a>
      <Badge label={node.data.data.cost?.toString() ?? "-"} --mr="5px" />
    </div>
    <div class="actions">
      <button class="icon" on:click={() => dispatch("add", node.data)}>
        <AddIcon />
      </button>
      <Dropdown right>
        <svelte:fragment slot="action">
          <button class="icon" aria-busy={moreActionLoadingUuid === node.data.uuid}>
            <MoreIcon />
          </button>
        </svelte:fragment>
        <svelte:fragment slot="items">
          <DropdownItem let:size label={$_("delete")} on:click={() => dispatch("delete", node.data)}>
            <DeleteForeverIcon width={size} height={size} />
          </DropdownItem>
          <DropdownItem label={$_("move")} on:click={() => dispatch("move", node.data)}>
            <MoveIcon color="#245b48" />
          </DropdownItem>
        </svelte:fragment>
      </Dropdown>
    </div>
  </div>
  {#if draged}
    <div id={node.data.uuid} class="drag-zone" class:enter-zone={dragedToId === node.data.uuid} />
  {/if}
</div>

<div class="children" class:expanded>
  {#if expanded}
    {#each node.children as n (n.data.id)}
      <svelte:self
        node={n}
        {draged}
        {dragedId}
        {dragedToId}
        {changeStatusLoadingUuid}
        {movingLoadingUuid}
        {moreActionLoadingUuid}
        on:add
        on:delete
        on:drop
        on:dragover
        on:dragleave
        on:changeState
        on:dragstart
        on:dragend
        on:dragenter
        on:move
      />
    {/each}
  {/if}
</div>

<style>
  .node {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--s2);
    padding: 0 var(--s2);
    background-color: var(--bg-primary-color);
    margin-top: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    transition: all var(--transition-duration);
    border-radius: 3px;
  }

  .node:hover {
    box-shadow: 0 13px 25px rgba(50, 50, 93, 0.1), 0 4px 9px rgba(50, 50, 93, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .drag-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 255, 255, 0.192);
  }

  .enter-zone {
    background-color: rgba(0, 255, 0, 0.4);
  }

  .canDrop {
    background-color: aquamarine;
  }

  .topLevel.node::before {
    content: "";
    position: absolute;
    background-color: #00000015;
    left: -25px;
    right: 0px;
    height: 1px;
    width: 25px;
  }

  .title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .title a {
    text-decoration: none;
    color: var(--text-primary-color);
  }

  .title a:hover {
    text-decoration: underline;
  }

  .content {
    flex: 1;
    padding: var(--s2) 0;
    display: flex;
    gap: var(--s3);
    flex-direction: row;
    align-items: center;
  }

  .node-icon {
    display: flex;
    align-items: center;
  }

  .children {
    margin-left: 50px;
    position: relative;
  }

  .children::before {
    content: "";
    position: absolute;
    background-color: #00000015;
    top: 0px;
    bottom: 0px;
    width: 1px;
    left: -25px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--s1);
  }
</style>
