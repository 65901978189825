<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"

  import type { RssSource } from "../models/RssSource"
  import type { RssSourceCategory } from "../models/RssSourceCategory"

  export let rssSource: RssSource
  export let rssSourceCategories: RssSourceCategory[]
  export let label: string
  export let loading: boolean = false

  const dispatch = createEventDispatcher<{
    follow: {
      rssSource: RssSource
      rssSourceCategory: RssSourceCategory
    }
  }>()
</script>

<Dropdown right>
  <svelte:fragment slot="action">
    <button class="primary" aria-busy={loading}>{label}</button>
  </svelte:fragment>
  <svelte:fragment slot="items">
    {#each rssSourceCategories as rssSourceCategory}
      <DropdownItem
        label={rssSourceCategory.title}
        on:click={() => dispatch("follow", { rssSourceCategory, rssSource })}
      />
    {/each}
  </svelte:fragment>
</Dropdown>
