import { derived, writable } from "svelte/store"

import { Api } from "../../services/Api"
import { filterMap } from "../../utils"
import { createArrayWritableStore } from "../../utils"
import type { Reaction, RssFeed } from "../models/RssFeed"
import type { RssSource } from "../models/RssSource"
import { rssSourcesStore } from "./RssSourcesStore"

function createNextRssFeedsStore() {
  const store = createArrayWritableStore<RssFeed>([], b => b.uuid)
  return {
    ...store,
    markAsReaded,
    nextRssFeed: () => {
      store.update(state => {
        const [first, ...rest] = state
        if (first.reaction === "Unreaded") {
          first.reaction = "Readed"
        }
        previusRssFeeds.addPreviusRssFeed(first)
        return rest
      })
    },
    updateReaction: (rssFeed: RssFeed, reaction: Reaction) => {
      nextRssFeeds.update(state =>
        filterMap(
          state,
          s => ({ ...s, reaction }),
          s => s.uuid === rssFeed.uuid
        )
      )
      previusRssFeeds.update(state =>
        filterMap(
          state,
          s => ({ ...s, reaction }),
          s => s.uuid === rssFeed.uuid
        )
      )
    }
  }
}
export const nextRssFeeds = createNextRssFeedsStore()

function createPreviusRssFeedsStore() {
  const store = createArrayWritableStore<RssFeed>([], b => b.uuid)
  return {
    ...store,
    addPreviusRssFeed: (rssFeed: RssFeed) => {
      store.update(state => [rssFeed, ...state].splice(0, 50))
    },
    previusRssFeed: () => {
      store.update(state => {
        if (state.length === 0) {
          return state
        }
        const [first, ...rest] = state
        nextRssFeeds.addItem(first)
        return rest
      })
    }
  }
}
export const previusRssFeeds = createPreviusRssFeedsStore()

export const nextRssFeedToShow = derived(nextRssFeeds, rssSources =>
  rssSources.length > 0 ? rssSources[0] : undefined
)

export const currentRssSource = derived([rssSourcesStore, nextRssFeedToShow], ([rssSources, nextRssFeedToShow]) =>
  rssSources.find(s => s.uuid === nextRssFeedToShow?.rss_source_uuid)
)

export const markAsReadedLoading = writable(false)
function markAsReaded(rssSource: RssSource, date: Date) {
  markAsReadedLoading.set(true)
  Api.rssSources
    .skipRssFeeds(rssSource, date)
    .then(({ readed }) => rssSourcesStore.rssFeedsDecrement(rssSource.uuid, readed))
    .finally(() => {
      markAsReadedLoading.set(false)
      rssSourcesStore.reloadRssSources()
    })
}
