<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import type { NoteData } from "../models/FsNode"

  export let note: NoteData

  let name = note.name

  const dispatch = createEventDispatcher<{
    edit_name: string
  }>()
</script>

<label for="note-title">{$_("note.title")}</label>

<input
  id="note-title"
  bind:value={name}
  on:blur={() => {
    if (note.name !== name) {
      dispatch("edit_name", name)
    }
  }}
/>

<style>
  input {
    width: 100%;
    padding: 12px 10px;
    font-size: 0.9rem;
    border-radius: 3px;
    border: 1px solid var(--border);
    color: var(--text-primary-color);
  }
</style>
