<script lang="ts">
  import MenuOpenIcon from "virtual:icons/ant-design/menu-fold-outlined"
  import MenuCloseIcon from "virtual:icons/ant-design/menu-unfold-outlined"

  import { tokenStore } from "../../account/TokenStore"
  import { Api } from "../../services/Api"
  import { drawer } from "../../stores/store"
  import UserMenu from "../UserMenu.svelte"
</script>

<header class:open={$drawer}>
  <button on:click={() => drawer.toggle()}>
    {#if $drawer}
      <MenuOpenIcon color="#333333" width={24} height={24} />
    {:else}
      <MenuCloseIcon color="#333333" width={24} height={24} />
    {/if}
  </button>
  <div class="content">
    <slot />
  </div>
  <div class="actions">
    <UserMenu username={$tokenStore?.username} scopes={$tokenStore?.scopes} on:logout={() => Api.auth.logout()} />
  </div>
</header>

<style>
  header {
    position: sticky;
    top: 0;
    height: var(--header-height);
    z-index: var(--zi, 100);
    display: grid;
    align-items: center;
    gap: var(--s3);
    grid-template-columns: 36px 1fr auto;
    padding: 0 var(--s2);
    border-bottom: 1px solid var(--border);
    background-color: var(--bg-primary-color);
    transition: margin 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  header.open {
    margin-left: var(--side-menu-width);
  }

  .content {
    height: var(--header-height);
    display: grid;
    align-items: center;
    padding: var(--s1) 0;
  }
</style>
