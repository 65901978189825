<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import FolderIcon from "virtual:icons/mdi/folder-outline"

  import Loader from "../../components/Loader.svelte"

  import { Api } from "../../services/Api"
  import type { FsNode } from "../models/FsNode"
  import NavigatorBreadcrumb from "./NavigatorBreadcrumb.svelte"

  export let startTarget: FsNode
  export let loading: boolean

  let target: FsNode = startTarget

  $: filesResponse = Api.fsNodes.getFiles(target.uuid)

  const dispatch = createEventDispatcher<{
    move: {
      target: FsNode
    }
  }>()
</script>

<div class="move-fs-node-modal">
  {#await filesResponse}
    <div class="navigator">
      <Loader />
    </div>
  {:then response}
    <div class="navigator">
      <NavigatorBreadcrumb ancestors={response.ancestors} on:click={({ detail }) => (target = detail)} />
      {#if response.childrens.length === 0}
        <div class="empty">{$_("empty")}</div>
      {/if}
      {#each response.childrens.filter(c => c.data.type === "Directory") as fsNode (fsNode.uuid)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="directory" on:click={() => (target = fsNode)}>
          <FolderIcon width={20} height={20} />
          {#if fsNode.data.type === "Note" || fsNode.data.type === "Directory"}
            <div class="directory-name">{fsNode.data.name}</div>
          {/if}
          {#if fsNode.data.type === "File" || fsNode.data.type === "Image"}
            <div class="directory-name">{fsNode.data.stem}</div>
          {/if}
        </div>
      {/each}
    </div>
  {/await}
  <div class="actions">
    <button class="primary" on:click={() => dispatch("move", { target })} aria-busy={loading}>{$_("move.here")}</button>
  </div>
</div>

<style>
  .move-fs-node-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 15px 15px 15px;
    border-top: 1px solid var(--border);
  }

  .navigator {
    overflow-y: auto;
    height: calc(100vh - 30em);
  }

  .directory {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0;
    cursor: pointer;
    flex-wrap: nowrap;
  }

  .directory-name {
    margin-left: 10px;
    color: var(--text-primary-color);
    text-decoration: underline;
  }

  .actions {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .empty {
    padding: 15px;
    color: var(--text-primary-color);
  }
</style>
