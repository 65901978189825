<script lang="ts">
  export let percent = 10
  export let size = 40
  export let thickness: number = 4
  export let progressColor = "#2196f3"
  export let circleColor = "#EEEEEE"
  export let labelColor = "#333333"

  $: half = size / 2
  $: radius = half - thickness / 2
  $: circumference = Math.PI * (radius * 2)
  $: dashoffset = circumference - (percent / 100) * circumference
</script>

<svg width={size} height={size}>
  <circle cx={half} cy={half} r={radius} fill="none" stroke={circleColor} stroke-width={thickness} />
  <circle
    class="progress"
    cx={half}
    cy={half}
    r={radius}
    fill="none"
    stroke={progressColor}
    stroke-width={thickness}
    style="--dashoffset: {dashoffset};--circumference: {circumference};--percent: {percent}"
  />
  <text class="label" fill={labelColor} x={half} y={half} dominant-baseline="middle" text-anchor="middle"
    >{percent}%</text
  >
</svg>

<style>
  .progress {
    transform: rotate(-90deg);
    transform-origin: center;
    stroke-dashoffset: var(--dashoffset);
    stroke-dasharray: var(--circumference);
  }

  .label {
    font-size: var(--label-font-size, 11px);
  }
</style>
