import type { CancelTokenSource } from "axios"

import { ApiService } from "../services/ApiService"
import type { RssSource } from "./models/RssSource"
import { rssSourceValidator } from "./models/RssSource"
import type { RssSourceCategory } from "./models/RssSourceCategory"
import { rssSourceCategoriesValidator, rssSourceCategoryValidator } from "./models/RssSourceCategory"

export class RssSourceCategoryService extends ApiService {
  public getRssSourceCategories(cancelToken?: CancelTokenSource): Promise<RssSourceCategory[]> {
    return this.typedRequest(
      {
        url: "/api/rss/sources/categories",
        method: "GET",
        cancelToken: cancelToken?.token
      },
      rssSourceCategoriesValidator
    )
  }

  public createRssSourceCategory(title: string, cancelToken?: CancelTokenSource): Promise<RssSourceCategory> {
    return this.typedRequest(
      {
        url: "/api/rss/sources/categories",
        method: "POST",
        data: { title },
        cancelToken: cancelToken?.token
      },
      rssSourceCategoryValidator
    )
  }

  public updateRssSourceCategory(
    title: string,
    rssSourceCategory: RssSourceCategory,
    cancelToken?: CancelTokenSource
  ): Promise<RssSourceCategory> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/categories/${rssSourceCategory.uuid}`,
        method: "PUT",
        data: { title },
        cancelToken: cancelToken?.token
      },
      rssSourceCategoryValidator
    )
  }

  public deleteRssSourceCategory(
    rssSourceCategory: RssSourceCategory,
    cancelToken?: CancelTokenSource
  ): Promise<{ deleted: number }> {
    return this.request({
      url: `/api/rss/sources/categories/${rssSourceCategory.uuid}`,
      method: "DELETE",
      cancelToken: cancelToken?.token
    })
  }

  public changeRssSourceCategory(rssSource: RssSource, rssSourceCategory: RssSourceCategory): Promise<RssSource> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/${rssSource.uuid}`,
        method: "PUT",
        data: {
          rss_source_category_uuid: rssSourceCategory.uuid
        }
      },
      rssSourceValidator
    )
  }
}
