<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import FilesIcon from "virtual:icons/mdi/file-multiple-outline"
  import LogoutIcon from "virtual:icons/mdi/logout"
  import RssIcon from "virtual:icons/mdi/rss"
  import ObjectivesIcon from "virtual:icons/tabler/subtask"

  import Dropdown from "./Dropdown.svelte"
  import DropdownItem from "./DropdownItem.svelte"
  import { Scope } from "../account/models/User"

  export let username: string | undefined

  export let scopes: Scope[] | undefined = []

  const dispatch = createEventDispatcher<{ logout: undefined }>()
</script>

<Dropdown right>
  <div slot="action" class="user">
    <div class="user-image">
      {`${(username ?? "User").substring(0, 1).toUpperCase()}${(username ?? "User").substring(1, 2).toLowerCase()}`}
    </div>
    <div class="username">
      {username ?? "User"}
    </div>
  </div>
  <svelte:fragment slot="items">
    {#if scopes?.includes("rss_feeds")}
      <DropdownItem let:size label={$_("rss.feeds")} href="/rss/feeds">
        <RssIcon width={size} height={size} />
      </DropdownItem>
    {/if}
    {#if scopes?.includes("files")}
      <DropdownItem let:size label={$_("menu.files.store")} href="/fs">
        <FilesIcon width={size} height={size} />
      </DropdownItem>
    {/if}
    {#if scopes?.includes("objectives")}
      <DropdownItem let:size label={$_("objectives")} href="/objectives">
        <ObjectivesIcon width={size} height={size} />
      </DropdownItem>
    {/if}
    <DropdownItem let:size label={$_("logout")} on:click={() => dispatch("logout")}>
      <LogoutIcon width={size} height={size} />
    </DropdownItem>
  </svelte:fragment>
</Dropdown>

<style>
  .user {
    --image-size: 30px;
    --user-padding: 4px;
    padding: var(--user-padding);
    display: flex;
    align-items: center;
    transition: color var(--transition-duration) cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    transition-duration: var(--transition-duration);
    transition-property: border-color;
  }

  .user:hover {
    border: 1px solid var(--border);
  }

  .user:active {
    position: relative;
    inset-block-start: 2px;
  }

  .user-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--image-size);
    height: var(--image-size);
    border-radius: calc(var(--image-size) / 2);
    background-color: var(--bg-accent-color);
    color: var(--text-secondary-color);
    font-size: 12px;
    font-weight: bold;
  }

  .username {
    display: none;
    color: var(--text-primary-color);
  }

  /* Mobile first tablet breakpoint */
  @media screen and (min-width: 640px) {
    .username {
      display: block;
      margin: 0 4px;
    }
  }
</style>
