<script lang="ts">
  import { _ } from "svelte-i18n"

  import Header from "../components/layout/Header.svelte"
  import Main from "../components/layout/Main.svelte"
  import SideMenu from "../components/layout/SideMenu.svelte"
</script>

<Header />
<SideMenu href="/" />

<Main>
  <h1>Admin screen</h1>
</Main>
