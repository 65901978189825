<script context="module">
  import "highlight.js/styles/github.css"
  import imsize from "@steelydylan/markdown-it-imsize"

  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return hljs.highlight(lang, str).value
        } catch (e) {
          console.error("Failed to highlight string")
        }
      }
      return "" // use external default escaping
    }
  }).use(imsize)
</script>

<script lang="ts">
  import hljs from "highlight.js/lib/common"
  import MarkdownIt from "markdown-it"

  export let source: string = ""
</script>

{@html md.render(source)}
