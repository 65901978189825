<script lang="ts">
  import { _ } from "svelte-i18n"

  import FolderIcon from "virtual:icons/mdi/folder-outline"
  import NoteTextIcon from "virtual:icons/mdi/note-text-outline"

  import { Api } from "../../services/Api"
  import { failure } from "../../toast"
  import { FsNode as FsNodeModel, getRenameName } from "../models/FsNode"
  import {
    fsNodesStore,
    selectedFsNodes,
    wantCreateDirectory,
    wantCreateNote,
    wantRenameFsNode
  } from "../stores/FilesStore"
  import FsNode from "./FsNode.svelte"
  import FsNodeForm from "./FsNodeForm.svelte"
  import FsNodeIcon from "./FsNodeIcon.svelte"

  export let parentUuid: string

  function handleCreateDirectory(name: string) {
    if (name !== "") {
      fsNodesStore
        .createDirectory(parentUuid, name)
        .then(() => wantCreateDirectory.update(() => false))
        .catch(error => failure($_(error.message)))
    } else {
      wantCreateDirectory.update(() => false)
    }
  }

  function handleCreateNote(name: string) {
    if (name !== "") {
      fsNodesStore
        .createNote(parentUuid, name)
        .then(() => wantCreateNote.update(() => false))
        .catch(error => failure($_(error.message)))
    } else {
      wantCreateNote.update(() => false)
    }
  }

  function handleRenameFsNode(fsNode: FsNodeModel, name: string) {
    if (name !== "") {
      fsNodesStore
        .renameFsNodeAction(fsNode.uuid, {
          name: { type: "Replace", value: name }
        })
        .catch(error => failure($_(error.message)))
    } else {
      wantRenameFsNode.set(undefined)
    }
  }
</script>

<div class="fs-nodes">
  {#if $wantCreateDirectory}
    <FsNodeForm
      on:save={({ detail }) => handleCreateDirectory(detail.name)}
      on:cancel={() => wantCreateDirectory.update(() => false)}
    >
      <FolderIcon color="#245b48" width={30} height={30} />
    </FsNodeForm>
  {/if}
  {#if $wantCreateNote}
    <FsNodeForm
      on:save={({ detail }) => handleCreateNote(detail.name)}
      on:cancel={() => wantCreateNote.update(() => false)}
    >
      <NoteTextIcon color="#3F51B5" width={30} height={30} />
    </FsNodeForm>
  {/if}
  {#if $fsNodesStore.length === 0}
    <div class="empty">{$_("empty")}</div>
  {/if}
  {#each $fsNodesStore as fsNode (fsNode.uuid)}
    {#if $wantRenameFsNode?.uuid === fsNode.uuid}
      <FsNodeForm
        initialName={getRenameName(fsNode.data)}
        on:save={({ detail }) => handleRenameFsNode(fsNode, detail.name)}
        on:cancel={() => wantRenameFsNode.set(undefined)}
      >
        <FsNodeIcon {fsNode} thumbnailUri={Api.fsNodes.getThumbnailUri(fsNode.uuid, 200, 200)} />
      </FsNodeForm>
    {:else}
      <FsNode
        {fsNode}
        thumbnailUri={Api.fsNodes.getThumbnailUri(fsNode.uuid, 100, 100)}
        selected={!!$selectedFsNodes.find(n => n.uuid === fsNode.uuid)}
        iconSize={35}
        on:click
        on:select
      />
    {/if}
  {/each}
</div>

<style>
  .fs-nodes {
    border-top: 1px solid var(--border);
  }

  .empty {
    padding: 20px;
    text-align: center;
    color: var(--text-unimportant-color);
  }
</style>
