<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"

  import SearchIcon from "virtual:icons/mdi/magnify"

  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import RssSourceItem from "../components/RssSourceItem.svelte"
  import RssSourcesByCategoriesMenu from "../components/RssSourcesByCategoriesMenu.svelte"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { findedRssSourcesStore, searchRssSourcesLoading } from "../stores/RssSourcesFindStore"
  import { loadRssSourcesByCategories, rssSourcesCategoriesStore, rssSourcesStore } from "../stores/RssSourcesStore"
  import type { RssSource } from "./../models/RssSource"
  import type { RssSourceCategory } from "./../models/RssSourceCategory"

  let query = ""
  const limit = 30

  onMount(() => {
    findedRssSourcesStore.searchRssSources(query, { offset: 0, limit })
  })

  onMount(loadRssSourcesByCategories)

  let searchLoading = false
  function search() {
    searchLoading = true
    Api.rssSources
      .searchRssSource(query, { offset: 0, limit })
      .then(rssSources => {
        searchLoading = false
        findedRssSourcesStore.set(rssSources)
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
        searchLoading = false
      })
  }

  let nextPageLoader = false
  let page = 1
  $: handleNextPage = () => {
    nextPageLoader = true
    Api.rssSources
      .searchRssSource(query, { offset: page * limit, limit })
      .then(rssSources => {
        page = page + 1
        findedRssSourcesStore.concat(rssSources)
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
      })
      .finally(() => {
        nextPageLoader = false
      })
  }

  let followRssSourceLoading: string | undefined = undefined
  function followRssSource(rssSource: RssSource, rssSourceCategory: RssSourceCategory) {
    followRssSourceLoading = rssSource.uuid
    Api.rssSources
      .followRssSource(rssSource, rssSourceCategory)
      .then(() => {
        followRssSourceLoading = undefined
        rssSourcesStore.addItem({ ...rssSource, rss_source_category_uuid: rssSourceCategory.uuid })
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
        followRssSourceLoading = undefined
      })
  }

  let unfollowLoading: string | undefined = undefined
  function unfollowRssSource(rssSource: RssSource) {
    unfollowLoading = rssSource.uuid
    Api.rssSources
      .unfollowRssSource(rssSource)
      .then(() => {
        unfollowLoading = undefined
        rssSourcesStore.removeItem(rssSource)
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
        unfollowLoading = undefined
      })
  }
</script>

<Header>
  <div class="search">
    <input type="search" bind:value={query} placeholder={$_("search")} />
    <button class="icon" on:click={search} aria-busy={searchLoading}>
      <SearchIcon />
    </button>
  </div>
</Header>
<SideMenu href="/rss/feeds">
  <div class="menu-items">
    <MenuItem selected label={$_("findNewRssSources")} href="/rss/sources/find" />
    <MenuItem label={$_("myRssSources")} href="/rss/sources" />
    <MenuItem label={$_("favorites")} href="/rss/feeds/liked" />
    <MenuItem
      label={$_("allCategories")}
      href="/rss/feeds"
      count={$rssSourcesStore.reduce((acc, i) => acc + i.unreaded, 0)}
    />
  </div>
  <div class="categories-menu">
    <RssSourcesByCategoriesMenu />
  </div>
</SideMenu>
<Main>
  <div class="content">
    {#if $searchRssSourcesLoading}
      <Loader size={40} />
    {:else}
      <div class="rss-sources">
        {#each $findedRssSourcesStore as rssSource (rssSource.uuid)}
          <div class="rss-sources-item">
            <RssSourceItem
              {rssSource}
              rssSourcesCategories={$rssSourcesCategoriesStore}
              followLoading={followRssSourceLoading === rssSource.uuid}
              unfollowLoading={unfollowLoading === rssSource.uuid}
              followed={!!$rssSourcesStore.find(s => s.uuid === rssSource.uuid)}
              on:follow={({ detail }) => followRssSource(detail.rssSource, detail.rssSourceCategory)}
              on:unfollow={({ detail }) => unfollowRssSource(detail)}
            />
          </div>
        {/each}
        {#if $findedRssSourcesStore.length === 0}
          <div class="no-more">{$_("noResult")}</div>
        {:else}
          <div class="load-more">
            <button class="primary" on:click={handleNextPage} aria-busy={nextPageLoader}>{$_("seeMore")}</button>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</Main>

<style>
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--s1);
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .rss-sources {
    max-width: var(--max-width);
    margin: auto;
    padding: 30px 15px 0 15px;
  }

  .rss-sources-item {
    margin-bottom: var(--s3);
  }

  .load-more {
    padding: 15px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-more {
    color: var(--text-unimportant-color);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding: 60px 0;
  }

  .menu-items {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border);
  }

  .categories-menu {
    flex: 1;
    overflow-y: auto;
  }
</style>
