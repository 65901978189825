import type { CancelTokenSource } from "axios"

import { ApiService } from "../services/ApiService"
import type { Pagination } from "../services/Pagination"
import { queryString } from "../utils"
import type { RssSource } from "./models/RssSource"
import { rssSourceValidator, rssSourcesValidator } from "./models/RssSource"
import type { RssSourceCategory } from "./models/RssSourceCategory"

export class RssSourcesService extends ApiService {
  public loadRssSources(pagination: Pagination, cancelToken?: CancelTokenSource): Promise<RssSource[]> {
    return this.typedRequest(
      { url: `/api/rss/sources${queryString({ ...pagination })}`, method: "GET", cancelToken: cancelToken?.token },
      rssSourcesValidator
    )
  }

  public searchRssSource(query: string, pagination: Pagination): Promise<RssSource[]> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/search${queryString({ ...pagination, query })}`,
        method: "GET"
      },
      rssSourcesValidator
    )
  }

  public getUnfollowedRssSources(pagination: Pagination): Promise<RssSource[]> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/unfollowed${queryString({ ...pagination })}`,
        method: "GET"
      },
      rssSourcesValidator
    )
  }

  public followRssSource(rssSource: RssSource, rssSourceCategory: RssSourceCategory): Promise<RssSource> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/${rssSource.uuid}/follow`,
        method: "POST",
        data: {
          rss_source_category_uuid: rssSourceCategory.uuid
        }
      },
      rssSourceValidator
    )
  }

  public unfollowRssSource(rssSource: RssSource): Promise<RssSource> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/${rssSource.uuid}/unfollow`,
        method: "PUT"
      },
      rssSourceValidator
    )
  }

  public getRssSource(rssSourceUuid: string, cancelToken?: CancelTokenSource): Promise<RssSource> {
    return this.typedRequest(
      {
        url: `/api/rss/sources/${rssSourceUuid}`,
        method: "GET",
        cancelToken: cancelToken?.token
      },
      rssSourceValidator
    )
  }

  public skipRssFeeds({ uuid }: RssSource, date: Date): Promise<{ readed: number }> {
    return this.request({
      url: `/api/rss/sources/${uuid}/skip`,
      method: "PUT",
      data: {
        date: date.toISOString()
      }
    })
  }
}
