<script lang="ts">
  import { createEventDispatcher } from "svelte"

  export let label: string

  let files: FileList

  const dispatch = createEventDispatcher<{
    upload: {
      files: FileList
    }
  }>()
</script>

<div class="uploader">
  <input type="file" multiple bind:files on:change={() => dispatch("upload", { files })} />
  <label for="upload-input">{label}</label>
</div>

<style>
  .uploader {
    min-width: 100px;
    position: relative;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--bg-secondary-color);
    border-radius: 3px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
  }

  .uploader:hover {
    opacity: 0.8;
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  label {
    color: var(--text-secondary-color);
    font-size: 0.9rem;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all var(--transition-duration);
    cursor: pointer;
  }
</style>
