<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"
  import { navigate } from "svelte-navigator"

  import ArrowLeftIcon from "virtual:icons/mdi/arrow-left"

  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import RssFeedContent from "../components/RssFeedContent.svelte"
  import RssSourcesByCategoriesMenu from "../components/RssSourcesByCategoriesMenu.svelte"

  import type { RssFeed } from "../../rss_feeds/models/RssFeed"
  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { rssSourcesStore } from "../stores/RssSourcesStore"

  export let uuid: string

  let rssFeedsLoading = false
  let rssFeed: RssFeed

  onMount(() => {
    Api.rssFeeds.getRssFeed(uuid).then(result => (rssFeed = result))
  })

  let likeLoadingUuid: string | undefined = undefined
  function reaction() {
    if (rssFeed) {
      likeLoadingUuid = rssFeed.uuid
      const newReaction = rssFeed.reaction === "Liked" ? "Readed" : "Liked"
      Api.rssFeeds
        .updateRssFeed(rssFeed, newReaction)
        .then(() => {
          likeLoadingUuid = undefined
          rssFeed.reaction = newReaction
        })
        .catch((errors: ApiErrors) => {
          // TODO handle error
          console.error(errors)
          likeLoadingUuid = undefined
        })
    }
  }
</script>

<Header>
  <button class="icon" title={$_("back")} on:click={() => navigate(-1)}>
    <ArrowLeftIcon />
  </button>
</Header>

<SideMenu href="/rss/feeds">
  <div class="menu-items">
    <MenuItem label={$_("findNewRssSources")} href="/rss/sources/find" />
    <MenuItem label={$_("myRssSources")} href="/rss/sources" />
    <MenuItem selected label={$_("favorites")} href="/rss/feeds/liked" />
    <MenuItem
      href="/rss/feeds"
      label={$_("allCategories")}
      count={$rssSourcesStore.reduce((acc, i) => acc + i.unreaded, 0)}
    />
  </div>
  <div class="categories-menu">
    <RssSourcesByCategoriesMenu />
  </div>
</SideMenu>

<Main>
  <div class="main">
    {#if rssFeedsLoading}
      <div class="loader">
        <Loader size={70} />
      </div>
    {/if}
    {#if rssFeed}
      <RssFeedContent {rssFeed} on:like={() => reaction()} likeLoading={!!likeLoadingUuid} />
    {/if}
  </div>
</Main>

<style>
  .main {
    padding-top: var(--s6);
    max-width: var(--max-width);
    margin: auto;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--s5);
  }
</style>
