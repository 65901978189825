import { writable } from "svelte/store"

import { Api } from "../../services/Api"
import type { Pagination } from "../../services/Pagination"
import { createArrayWritableStore } from "../../utils"
import type { RssSource } from "../models/RssSource"

function createFindedRssSourcesStore() {
  const store = createArrayWritableStore<RssSource>([], b => b.uuid)
  return {
    ...store,
    searchRssSources
  }
}
export const findedRssSourcesStore = createFindedRssSourcesStore()

export const searchRssSourcesLoading = writable(false)
function searchRssSources(query: string, pagination: Pagination) {
  searchRssSourcesLoading.set(true)
  return Api.rssSources
    .searchRssSource(query, pagination)
    .then(findedRssSourcesStore.set)
    .finally(() => searchRssSourcesLoading.set(false))
}
