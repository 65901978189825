<script lang="ts">
  import { Route, Router } from "svelte-navigator"

  import LoginScreen from "./account/LoginScreen.svelte"
  import SignupScreen from "./account/SignupScreen.svelte"
  import AdminLoginScreen from "./admin/AdminLoginScreen.svelte"
  import AdminScreen from "./admin/AdminScreen.svelte"
  import DashboardScreen from "./dashboard/DashboardScreen.svelte"
  import FilesStoreScreen from "./files_store/screens/FilesStoreScreen.svelte"
  import FilesStoreTrashBinScreen from "./files_store/screens/FilesStoreTrashBinScreen.svelte"
  import NoteScreen from "./files_store/screens/NoteScreen.svelte"
  import DocumentationScreen from "./objectives/DocumentationScreen.svelte"
  import MembersScreen from "./objectives/MembersScreen.svelte"
  import ObjectiveScreen from "./objectives/ObjectiveScreen.svelte"
  import ObjectivesScreen from "./objectives/ObjectivesScreen.svelte"
  import RssFeedViewScreen from "./rss_feeds/screens/RssFeedViewScreen.svelte"
  import RssFeedsLikedScreen from "./rss_feeds/screens/RssFeedsLikedScreen.svelte"
  import RssFeedsScreen from "./rss_feeds/screens/RssFeedsScreen.svelte"
  import RssSourcesFindScreen from "./rss_feeds/screens/RssSourcesFindScreen.svelte"
  import RssSourcesScreen from "./rss_feeds/screens/RssSourcesScreen.svelte"
</script>

<Router>
  <Route path="/" component={DashboardScreen} />
  <Route path="index.html" component={DashboardScreen} />

  <Route path="login" component={LoginScreen} />
  <Route path="signup" component={SignupScreen} />

  <Route path="rss/sources" component={RssSourcesScreen} />
  <Route path="rss/sources/find" component={RssSourcesFindScreen} />
  <Route path="rss/feeds/liked" component={RssFeedsLikedScreen} />
  <Route path="rss/feeds/liked/view/:id" let:params>
    <RssFeedViewScreen uuid={params.id} />
  </Route>
  <Route path="rss/feeds" component={RssFeedsScreen} />
  <Route path="rss/feeds/:type" component={RssFeedsScreen} />
  <Route path="rss/feeds/:type/:id" component={RssFeedsScreen} />

  <Route path="fs">
    <FilesStoreScreen uuid={undefined} />
  </Route>
  <Route path="fs/:id" let:params>
    <FilesStoreScreen uuid={params.id} />
  </Route>
  <Route path="fs/note/:id" let:params>
    <NoteScreen uuid={params.id} />
  </Route>

  <Route path="fs/trash-bin">
    <FilesStoreTrashBinScreen uuid={undefined} />
  </Route>
  <Route path="fs/trash-bin/:id" let:params>
    <FilesStoreTrashBinScreen uuid={params.id} />
  </Route>

  <Route path="objectives">
    <ObjectivesScreen />
  </Route>
  <Route path="objectives/:id" let:params>
    <ObjectiveScreen uuid={params.id} />
  </Route>

  <Route path="objectives/documentation/:id" let:params>
    <DocumentationScreen uuid={params.id} />
  </Route>
  <Route path="objectives/members/:id" let:params>
    <MembersScreen uuid={params.id} />
  </Route>

  <Route path="admin" component={AdminScreen} />
  <Route path="admin/login" component={AdminLoginScreen} />
</Router>
