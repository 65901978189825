<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import Image from "../../components/Image.svelte"

  import type { RssSource } from "../models/RssSource"
  import type { RssSourceCategory } from "../models/RssSourceCategory"
  import FollowAction from "./FollowAction.svelte"

  export let selected: boolean = false
  export let followed: boolean = false
  export let followLoading: boolean = false
  export let unfollowLoading: boolean = false
  export let rssSource: RssSource
  export let rssSourcesCategories: RssSourceCategory[]

  let imageUrl = rssSource && (rssSource.icon_url || rssSource.cover_url)

  const dispatch = createEventDispatcher<{
    unfollow: RssSource
  }>()
</script>

<div class="rss-source" class:selected>
  <div class="image">
    <Image src={imageUrl} alt="RSS source" --width="100px" --height="100px" />
  </div>
  <div class="content">
    <h3 class="title">{rssSource.title}</h3>
    <a class="url" href={rssSource.url}>{rssSource.url}</a>
    {#if rssSource.description}
      <p class="description">{rssSource.description}</p>
    {/if}
    <div class="actions">
      {#if followed}
        <button class="primary" on:click={() => dispatch("unfollow", rssSource)} aria-busy={unfollowLoading}>
          {$_("rss.unfollow")}
        </button>
      {:else}
        <FollowAction
          label={$_("rss.follow")}
          loading={followLoading}
          rssSourceCategories={rssSourcesCategories}
          {rssSource}
          on:follow
        />
      {/if}
    </div>
  </div>
</div>

<style>
  .rss-source {
    padding: var(--s3);
    display: flex;
    flex-direction: row;
    align-items: start;
    border: 1px solid var(--border);
  }
  .title {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-primary-color);
    margin-bottom: 10px;
  }

  .content {
    margin-left: 15px;
    flex: 1;
  }

  .description {
    font-size: 0.9rem;
  }

  .image {
    width: 100px;
    height: 100px;
  }

  .url {
    display: block;
    font-size: 0.9rem;
    color: var(--text-unimportant-color);
    text-decoration: none;
    margin-bottom: 15px;
  }

  .image:hover {
    opacity: 0.8;
  }

  .selected {
    background-color: #f1f1f1;
  }

  .actions {
    display: flex;
    justify-content: end;
  }
</style>
