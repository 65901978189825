<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import CloseIcon from "virtual:icons/mdi/close"

  import { key_action } from "../actions/key_action"

  export let title: string

  const dispatch = createEventDispatcher()
  const close = () => dispatch("close")
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal-background" on:click={close} />

<div class="modal" role="dialog" aria-modal="true" use:key_action={{ Escape: close }}>
  <div class="header">
    <h2 class="title">{title}</h2>
    <button class="icon" on:click={close}>
      <CloseIcon />
    </button>
  </div>
  <slot />
</div>

<style>
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    width: calc(100vw - 4em);
    max-width: 32em;
    min-height: 300px;
    max-height: calc(100vh - 4em);
    transform: translate(-50%, -50%);
    border-radius: 0.2em;
    background: white;
    z-index: 1000;
  }

  .header {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    word-break: break-all;
    overflow: hidden;
  }
</style>
