<script lang="ts">
  import { createEventDispatcher } from "svelte"

  import HomeIcon from "virtual:icons/mdi/home-outline"

  import { FsNode, getFsNodeName } from "../models/FsNode"

  export let ancestors: FsNode[]
  const dispatch = createEventDispatcher<{ click: FsNode }>()
</script>

<div class="breadcrumb">
  {#each ancestors as ancestor (ancestor.uuid)}
    {#if ancestor.data.type === "Root"}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="root" on:click={() => dispatch("click", ancestor)}>
        <HomeIcon width={20} height={20} />
      </div>
    {:else}
      <div class="separaton">/</div>
      {#if ancestor.data.type === "File" || ancestor.data.type === "Image" || ancestor.data.type === "Directory"}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="crumb" on:click={() => dispatch("click", ancestor)}>{getFsNodeName(ancestor.data)}</div>
      {/if}
    {/if}
  {/each}
</div>

<style>
  .breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .root {
    cursor: pointer;
  }

  .separaton {
    color: var(--text-primary-color);
    margin-left: 5px;
    margin-right: 5px;
  }

  .crumb {
    color: var(--text-primary-color);
    text-decoration: none;
    cursor: pointer;
  }

  .crumb:hover {
    text-decoration: underline;
  }
</style>
