<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { getFieldErrorMessage } from "../../utils"
  import type { CreateObjective, Objective, ObjectiveType } from "../Objective"

  export let parentUuid: string

  export const objectiveTypes: ObjectiveType[] = ["Bug", "Task"]
  let objectiveType: ObjectiveType = "Task"
  let title = ""
  let description = ""
  let loading: boolean = false
  let requestErrors: ApiErrors | undefined = undefined

  $: nameError = getFieldErrorMessage("name", (k, values) => $_(k, { values }), requestErrors)

  const dispatch = createEventDispatcher<{
    added: Objective
  }>()

  function submit() {
    const createObjective: CreateObjective = {
      type: objectiveType,
      name: title,
      description
    }
    loading = true
    Api.objectives
      .createObjective(parentUuid, createObjective)
      .then(result => dispatch("added", result))
      .catch((errors: ApiErrors) => (requestErrors = errors))
      .finally(() => (loading = false))
  }
</script>

<div class="modal">
  <div class="form-field">
    <label for="objectiveType">{$_("form.type")}</label>
    <select bind:value={objectiveType}>
      {#each objectiveTypes as objectiveType}
        <option value={objectiveType}>{$_(objectiveType)}</option>
      {/each}
    </select>
  </div>
  <label for="title">
    {$_("form.title")}
    <input id="title" type="text" bind:value={title} />
    {#if nameError}
      <div class="error">{nameError}</div>
    {/if}
  </label>
  <label for="description">
    {$_("description")}
    <textarea id="description" bind:value={description} />
  </label>
  {#if requestErrors}
    <div class="error">{$_(requestErrors.message)}</div>
  {/if}
  <div>
    <button class="primary" aria-busy={loading} on:click={submit}>
      {$_("add")}
    </button>
  </div>
</div>

<style>
  .modal {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
    padding: 0 var(--s3) var(--s3) var(--s3);
  }

  .error {
    color: var(--text-negative-color);
  }
</style>
