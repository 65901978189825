import { writable } from "svelte/store"

import { Api } from "../services/Api"
import type { Token } from "./models/Token"

export const tokenStore = (() => {
  const store = writable<Token | undefined>(undefined, set => {
    Api.auth.decodeToken().then(token => set(token))
  })

  return {
    ...store,
    reinit: () => {
      Api.auth.decodeToken().then(token => store.set(token))
    }
  }
})()
