<script lang="ts">
  import { link } from "svelte-navigator"

  import Image from "../../components/Image.svelte"

  export let href = ""
  export let imgsrc = ""
  export let title = ""
  export let content = ""
</script>

<article class="cards">
  <div class="image">
    <Image src={imgsrc} alt="article" --width="100px" --min-height="100px" --object-fit="cover" />
  </div>
  <div class="content">
    <a use:link {href} class="title">{title}</a>
    <p>{content}</p>
  </div>
  <div class="actions">
    <slot />
  </div>
</article>

<style>
  .cards {
    display: grid;
    gap: var(--s3);
    grid-template-areas: "image content actions";
    grid-template-columns: 100px 1fr 30px;
    grid-template-rows: 100px;
    margin-bottom: var(--mb, 0);
  }

  .image {
    width: 100px;
    grid-area: image;
  }

  .content {
    grid-area: content;
  }

  .title {
    color: var(--color-dark);
    text-decoration: none;
    font-size: var(--fs-3);
  }

  .title:hover {
    text-decoration: underline;
  }

  .title:visited {
    color: var(--color-grey);
  }

  p {
    font-size: var(--fs-6);
  }

  .actions {
    grid-area: actions;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
</style>
