import type { CancelTokenSource } from "axios"

import { ApiService } from "../services/ApiService"
import type { Patch } from "../utils"
import { queryString } from "../utils"
import type { FsNode, FsNodeRootType, FsNodesResponse, UploadResult } from "./models/FsNode"

export class FsNodesService extends ApiService {
  public getFiles(
    uuid?: string,
    root_type?: FsNodeRootType,
    cancelToken?: CancelTokenSource
  ): Promise<FsNodesResponse> {
    const path = uuid ? `api/fs/${uuid}` : "api/fs"
    const query = root_type ? `?root_type=${root_type}` : ``
    return this.request({
      url: `/${path}${query}`,
      method: "GET",
      cancelToken: cancelToken?.token
    })
  }

  public upload(
    uuid: string,
    formData: FormData,
    onUploadProgress: (event: any) => void,
    cancelToken?: CancelTokenSource
  ): Promise<UploadResult[]> {
    return this.request({
      url: `/api/fs/upload/${uuid}`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      cancelToken: cancelToken?.token,
      onUploadProgress
    })
  }

  public createDirectory(parent_uuid: string, name: string, cancelToken?: CancelTokenSource): Promise<FsNode> {
    return this.request({
      url: `/api/fs/directories`,
      method: "POST",
      data: { parent_uuid, name },
      cancelToken: cancelToken?.token
    })
  }

  public moveFsNode(source_uuid: string, destination_uuid: string, cancelToken?: CancelTokenSource) {
    return this.request({
      url: `/api/fs`,
      method: "PUT",
      data: { source_uuid, destination_uuid },
      cancelToken: cancelToken?.token
    })
  }

  public deleteFsNode(uuid: string, cancelToken?: CancelTokenSource): Promise<void> {
    return this.request({
      url: `/api/fs/${uuid}`,
      method: "DELETE",
      cancelToken: cancelToken?.token
    })
  }

  public cleanup(cancelToken?: CancelTokenSource) {
    return this.request({
      url: `/api/fs/cleanup`,
      method: "DELETE",
      cancelToken: cancelToken?.token
    })
  }

  public getDownloadUri(uuid: string, mode: "Preview" | "Download" = "Download"): string {
    return `${this.config.baseURL}/api/fs/download/${uuid}?mode=${mode}&access_token=${this.storage.getItemSync(
      this.STORAGE_AUTH_TOKEN_KEY
    )}`
  }

  public getThumbnailUri(uuid: string, width: number, height: number): string {
    const queries = queryString({
      access_token: this.storage.getItemSync(this.STORAGE_AUTH_TOKEN_KEY),
      width,
      height
    })
    return `${this.config.baseURL}/api/fs/images/${uuid}${queries}`
  }

  public createNote(parent_uuid: string, name: string, cancelToken?: CancelTokenSource): Promise<FsNode> {
    return this.request({
      url: `/api/fs/notes`,
      method: "POST",
      data: { parent_uuid, name, content: "" },
      cancelToken: cancelToken?.token
    })
  }

  public patchFsNode(
    uuid: string,
    patch: {
      name?: Patch<string>
      content?: Patch<string>
    },
    cancelToken?: CancelTokenSource
  ): Promise<FsNode> {
    return this.request({
      url: `/api/fs/${uuid}`,
      method: "PATCH",
      data: patch,
      cancelToken: cancelToken?.token
    })
  }
}
