<script lang="ts">
  import { _ } from "svelte-i18n"

  import type { ObjectiveType } from "../Objective"
  import ObjectiveTypeIcon from "./ObjectiveTypeIcon.svelte"

  export let objectiveType: ObjectiveType
  export let iconSize = 18

  export let disabled = false
</script>

<div class:active={!disabled} class="container">
  <ObjectiveTypeIcon {objectiveType} {iconSize} />
  <div class="title">{$_(objectiveType)}</div>
</div>

<style>
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
  }

  .active:hover {
    background-color: rgba(156, 156, 156, 0.2);
  }

  .title {
    margin-left: 5px;
    font-size: 0.9rem;
  }
</style>
