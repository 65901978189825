import { array, literal, object, string, union } from "idonttrustlikethat"

import { readableValidator } from "../../rss_feeds/models/Readable"
import { indefinable } from "../../utils"

export const reactionValidator = union(
  literal("Unreaded"),
  literal("Readed"),
  literal("ReadLater"),
  literal("Viewed"),
  literal("Liked"),
  literal("Disliked"),
  literal("Archived")
)
export type Reaction = typeof reactionValidator.T

export const rssValidator = object({
  id: string,
  title: indefinable(string),
  content: indefinable(string),
  summary: indefinable(string),
  author: indefinable(string),
  published: indefinable(string),
  updated: indefinable(string),
  alternate: indefinable(string),
  keywords: indefinable(array(string)),
  enclosure: indefinable(string),
  fingerprint: indefinable(string)
})
export type Rss = typeof rssValidator.T

export const rssFeedValidator = object({
  uuid: string,
  rss_url: string,
  rss: indefinable(rssValidator),
  readable: indefinable(readableValidator),
  created_at: string,
  updated_at: string,
  rss_source_uuid: string,
  reaction: reactionValidator
})
export const rssFeedsValidator = array(rssFeedValidator)
export type RssFeed = typeof rssFeedValidator.T
