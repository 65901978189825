import { array, object, string } from "idonttrustlikethat"

export const rssSourceCategoryValidator = object({
  uuid: string,
  title: string,
  created_at: string,
  updated_at: string,
  user_uuid: string
})
export const rssSourceCategoriesValidator = array(rssSourceCategoryValidator)
export type RssSourceCategory = typeof rssSourceCategoryValidator.T
