<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import type { ApiErrors } from "../../services/ApiError"
  import { getFieldErrorMessage } from "../../utils"

  export let loading: boolean
  export let errors: ApiErrors | undefined

  let email = ""

  $: emailError = getFieldErrorMessage("email", (k, values) => $_(k, { values }), errors)

  const dispatch = createEventDispatcher<{
    added: string
  }>()
</script>

<div class="modal">
  <label for="objectiveType">
    {$_("form.email")}
    <input id="email" type="email" bind:value={email} />
    {#if emailError}
      <div class="error">{emailError}</div>
    {/if}
  </label>
  {#if errors}
    <div class="error">{$_(errors.message)}</div>
  {/if}
  <div class="actions">
    <button class="primary" aria-busy={loading} on:click={() => dispatch("added", email)}>{$_("add")}</button>
  </div>
</div>

<style>
  .modal {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
    padding: 0 var(--s3) var(--s3) var(--s3);
  }

  .error {
    color: var(--text-negative-color);
  }

  .actions {
    margin-top: var(--s3);
  }
</style>
