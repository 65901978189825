<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"

  import AddIcon from "virtual:icons/mdi/plus"

  import Loader from "../components/Loader.svelte"
  import MenuItem from "../components/MenuItem.svelte"
  import Modal from "../components/Modal.svelte"
  import Header from "../components/layout/Header.svelte"
  import Main from "../components/layout/Main.svelte"
  import SideMenu from "../components/layout/SideMenu.svelte"

  import EmptyPanel from "../ui_kit/common/EmptyPanel.svelte"
  import AddProjectModal from "./components/AddProjectModal.svelte"
  import MemberInvitation from "./components/MemberInvitation.svelte"
  import ProjectItem from "./components/ProjectItem.svelte"
  import { acceptInvitationLoading, declineInvitationLoading, projectInvitationsStore } from "./stores/MembersStore"
  import { deleteProjectLoading, loadProjectsLoading, projectsStore } from "./stores/ProjectStore"

  onMount(() => {
    projectsStore.loadProjects()
    projectInvitationsStore.loadProjectInvitations()
  })

  let addProject = false
</script>

<Header>
  <div>
    <button class="primary" on:click={() => (addProject = true)}>
      {$_("objectives.add.project")}
    </button>
  </div>
</Header>
<SideMenu href="/objectives">
  <MenuItem label={$_("projects")} href="/objectives" />
</SideMenu>
<Main>
  {#if $loadProjectsLoading}
    <div class="loader">
      <Loader size={40} />
    </div>
  {:else}
    <div class="projects">
      <h1>{$_("projects")}</h1>
      <div class="invitations">
        {#each $projectInvitationsStore as invitation (invitation.uuid)}
          <MemberInvitation
            member={invitation}
            acceptLoading={$acceptInvitationLoading === invitation.project_uuid}
            declineLoading={$declineInvitationLoading === invitation.project_uuid}
            on:accept={() => {
              projectInvitationsStore.acceptInvitation(invitation.project_uuid).then(() => {
                projectsStore.reloadProjects()
              })
            }}
            on:decline={() => {
              projectInvitationsStore.declineInvitation(invitation.project_uuid)
            }}
          />
        {/each}
      </div>
      {#each $projectsStore as project (project.uuid)}
        <div class="project-item">
          <ProjectItem
            {project}
            on:delete={({ detail }) => {
              if (confirm($_("confirmDelete"))) {
                projectsStore.deleteProject(detail)
              }
            }}
            deleteLoading={project.uuid === $deleteProjectLoading}
          />
        </div>
      {:else}
        <EmptyPanel label={$_("no.projects")}>
          <button class="primary" on:click={() => (addProject = true)}>{$_("create.new.project")}</button>
        </EmptyPanel>
      {/each}
    </div>
  {/if}

  {#if addProject}
    <Modal title={$_("addProject")} on:close={() => (addProject = false)}>
      <AddProjectModal
        on:added={({ detail }) => {
          projectsStore.addItem(detail)
          addProject = false
        }}
      />
    </Modal>
  {/if}
</Main>

<style>
  .loader {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .projects {
    width: 100%;
    height: inherit;
    padding: 30px 15px 0 15px;
    max-width: var(--max-width);
    margin: auto;
  }

  .invitations {
    padding-bottom: var(--s3);
  }

  .project-item {
    margin-bottom: 15px;
  }
</style>
