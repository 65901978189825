<script lang="ts">
  import TaskIcon from "virtual:icons/clarity/tasks-line"
  import BugIcon from "virtual:icons/mdi/bug-outline"
  import FileTreeIcon from "virtual:icons/mdi/file-tree-outline"

  import type { ObjectiveType } from "../Objective"

  export let objectiveType: ObjectiveType
  export let iconSize = 20
</script>

{#if objectiveType === "ProjectRoot"}
  <FileTreeIcon width={iconSize} height={iconSize} />
{:else if objectiveType === "Bug"}
  <BugIcon width={iconSize} height={iconSize} color="#931a1a" />
{:else if objectiveType === "Task"}
  <TaskIcon width={iconSize} height={iconSize} color="#d2cd53" />
{/if}
