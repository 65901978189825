import type { CancelTokenSource } from "axios"

import type {
  CreateObjective,
  CreateRootObjective,
  MoveObjective,
  Objective,
  ObjectiveData,
  ObjectiveType,
  ObjectivesResponse,
  PatchObjective
} from "../objectives/Objective"
import { ApiService } from "../services/ApiService"
import type { Pagination } from "../services/Pagination"
import { queryString } from "../utils"
import type { Member, MemberStatus } from "./Member"

export class ObjectivesService extends ApiService {
  public getProjects(pagination: Pagination, cancelToken?: CancelTokenSource): Promise<Objective[]> {
    return this.request({
      url: `/api/objectives${queryString({ ...pagination })}`,
      method: "GET",
      cancelToken: cancelToken?.token
    })
  }

  public getObjectives(
    parentUuid: string,
    types?: ObjectiveType[],
    depth?: number,
    cancelToken?: CancelTokenSource
  ): Promise<ObjectivesResponse> {
    return this.request({
      url: `/api/objectives/${parentUuid}${queryString({ types: types?.join(","), depth })}`,
      method: "GET",
      cancelToken: cancelToken?.token
    })
  }

  public createProject(projectRoot: ObjectiveData, cancelToken?: CancelTokenSource): Promise<Objective> {
    return this.request({
      url: `/api/objectives`,
      method: "POST",
      data: { data: projectRoot },
      cancelToken: cancelToken?.token
    })
  }

  public deleteObjective(objective: Objective, cancelToken?: CancelTokenSource): Promise<void> {
    return this.request({
      url: `/api/objectives/${objective.uuid}`,
      method: "DELETE",
      cancelToken: cancelToken?.token
    })
  }

  public createRootObjective(
    createRootObjective: CreateRootObjective,
    cancelToken?: CancelTokenSource
  ): Promise<Objective> {
    return this.request({
      url: `/api/objectives`,
      method: "POST",
      data: createRootObjective,
      cancelToken: cancelToken?.token
    })
  }

  public createObjective(
    parentUuid: string,
    createObjective: CreateObjective,
    cancelToken?: CancelTokenSource
  ): Promise<Objective> {
    return this.request({
      url: `/api/objectives/${parentUuid}`,
      method: "POST",
      data: createObjective,
      cancelToken: cancelToken?.token
    })
  }

  public moveObjective(moveObjective: MoveObjective, cancelToken?: CancelTokenSource): Promise<Objective> {
    return this.request({
      url: `/api/objectives/move`,
      method: "PUT",
      data: moveObjective,
      cancelToken: cancelToken?.token
    })
  }

  public patchObjective(
    objectiveUuid: string,
    patchObjective: PatchObjective,
    cancelToken?: CancelTokenSource
  ): Promise<Objective> {
    return this.request({
      url: `/api/objectives/${objectiveUuid}`,
      method: "PATCH",
      data: patchObjective,
      cancelToken: cancelToken?.token
    })
  }

  public transformObjective(
    objectiveUuid: string,
    to: ObjectiveType,
    cancelToken?: CancelTokenSource
  ): Promise<Objective> {
    return this.request({
      url: `/api/objectives/${objectiveUuid}/transform`,
      method: "PUT",
      data: {
        kind: to
      },
      cancelToken: cancelToken?.token
    })
  }

  public getMembers(uuid: string, cancelToken?: CancelTokenSource): Promise<Member[]> {
    return this.request({
      url: `/api/objectives/${uuid}/members`,
      method: "GET",
      cancelToken: cancelToken?.token
    })
  }

  public inviteMember(uuid: string, email: string, cancelToken?: CancelTokenSource): Promise<Member> {
    return this.request({
      url: `/api/objectives/${uuid}/members`,
      method: "POST",
      data: {
        email
      },
      cancelToken: cancelToken?.token
    })
  }

  public projectInvitations(cancelToken?: CancelTokenSource): Promise<Member[]> {
    return this.request({
      url: `/api/objectives/members/invitations`,
      method: "GET",
      cancelToken: cancelToken?.token
    })
  }
  public invitationResponse(
    projectUuid: string,
    status: Extract<MemberStatus, "Accepted" | "Declined">,
    cancelToken?: CancelTokenSource
  ): Promise<Member> {
    return this.request({
      url: `/api/objectives/${projectUuid}/members/invitations`,
      method: "PUT",
      data: {
        status
      },
      cancelToken: cancelToken?.token
    })
  }
}
