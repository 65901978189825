<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"
  import { navigate } from "svelte-navigator"

  import Loader from "../components/Loader.svelte"
  import MenuItem from "../components/MenuItem.svelte"
  import Modal from "../components/Modal.svelte"
  import Header from "../components/layout/Header.svelte"
  import Main from "../components/layout/Main.svelte"
  import SideMenu from "../components/layout/SideMenu.svelte"

  import AddMemberModal from "./components/AddMemberModal.svelte"
  import MemberItem from "./components/MemberItem.svelte"
  import {
    inviteMemberError,
    inviteMemberLoading,
    loadMembersLoading,
    membersStore,
    wantInviteMember
  } from "./stores/MembersStore"

  export let uuid: string

  onMount(() => membersStore.loadMembers(uuid))
</script>

<Header />

<SideMenu href="/objectives">
  <MenuItem label={$_("projects")} href="/objectives" />
  <MenuItem label={$_("members")} href={`/objectives/members/${uuid}`} />
</SideMenu>

<Main>
  <div class="toolbar">
    <button on:click={() => navigate(-1)}> {$_("back")}</button>
    <button class="primary" on:click={() => ($wantInviteMember = true)}>{$_("invite")}</button>
  </div>
  {#if $loadMembersLoading}
    <div class="loader">
      <Loader size={40} />
    </div>
  {:else}
    <div class="members">
      <h1>{$_("project.members")}</h1>
      {#each $membersStore as member (member.uuid)}
        <MemberItem {member} />
      {/each}
    </div>
  {/if}
  {#if $wantInviteMember}
    <Modal title={$_("add.member")} on:close={() => ($wantInviteMember = false)}>
      <AddMemberModal
        loading={$inviteMemberLoading}
        errors={$inviteMemberError}
        on:added={({ detail }) => {
          membersStore.inviteMember(uuid, detail).then(() => {
            if (!$inviteMemberError) {
              $wantInviteMember = false
            }
          })
        }}
      />
    </Modal>
  {/if}
</Main>

<style>
  .members {
    width: 100%;
    height: inherit;
    padding: 30px 15px 0 15px;
    max-width: var(--max-width);
    margin: auto;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: var(--header-height);
    padding: 4px 15px;
    border-bottom: 1px solid var(--border);

    background-color: var(--bg-primary-color);
    box-shadow: 0px 0px 5px 2px #dbdbdb;

    margin-bottom: 16px;
  }
</style>
