<script lang="ts">
  import type { TreeNode } from "../../utils"
  import type { Objective } from "../Objective"

  export let node: TreeNode<Objective>

  function scrollToElementId(id: string) {
    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
      block: "center"
    })
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<li class="table-of-content" on:click={() => scrollToElementId(node.data.uuid)}>
  {node.data.data.name}
</li>

<ul>
  {#each node.children as n (n.data.id)}
    <svelte:self node={n} />
  {/each}
</ul>

<style>
  ul {
    margin: 0;
    padding: 0;
    padding-left: 16px;
    list-style: none;
  }

  li {
    padding: 5px 0;
    color: var(--color-light);
    font-size: 0.9rem;
    cursor: pointer;
    list-style: none;
  }

  li:hover {
    text-decoration: underline;
  }
</style>
