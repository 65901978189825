<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"
  import { link } from "svelte-navigator"

  import DeleteForeverIcon from "virtual:icons/mdi/delete-forever-outline"
  import MoreIcon from "virtual:icons/mdi/dots-vertical"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"
  import ProgressBar from "../../components/ProgressBar.svelte"

  import type { Objective } from "../Objective"

  export let project: Objective
  export let deleteLoading: boolean

  const dispatch = createEventDispatcher<{
    delete: Objective
    edit: Objective
  }>()

  $: percent = Math.floor(((project.data.completed || 0) * 100) / (project.data.cost || 1)) || 0
</script>

<div class="project">
  <div class="progress">
    <ProgressBar {percent} />
  </div>
  <div class="content">
    <a use:link class="name" href={`/objectives/${project.uuid}`}>{project.data.name}</a>
  </div>
  <div class="actions">
    <Dropdown right>
      <svelte:fragment slot="action">
        <button class="icon" aria-busy={deleteLoading}>
          <MoreIcon />
        </button>
      </svelte:fragment>
      <svelte:fragment slot="items">
        <DropdownItem let:size label={$_("delete")} on:click={() => dispatch("delete", project)}>
          <DeleteForeverIcon width={size} height={size} />
        </DropdownItem>
      </svelte:fragment>
    </Dropdown>
  </div>
</div>

<style>
  .project {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--s3);
    background-color: white;
    border-radius: 3px;
    border: 1px solid var(--border);
  }

  .progress {
    margin-right: var(--s3);
  }

  .content {
    flex: 1;
  }

  .name {
    color: var(--text-primary-color);
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .name:hover {
    text-decoration: underline;
  }
</style>
