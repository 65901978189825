<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import type { ApiErrors } from "../../services/ApiError"
  import type { FsNode } from "../models/FsNode"
  import { fsNodesStore } from "../stores/FilesStore"

  export let fsNodes: FsNode[]

  let loading = false

  const dispatch = createEventDispatcher<{
    deleted: {
      fsNode: FsNode
    }
    errors: {
      errors: ApiErrors
    }
    multi_move: undefined
  }>()

  $: handleDeleteFsNodes = () => {
    loading = true
    Promise.all(
      fsNodes.map(fsNode =>
        fsNodesStore
          .deleteFsNodeAction(fsNode.uuid)
          .then(() => {
            dispatch("deleted", { fsNode })
          })
          .catch(errors => {
            dispatch("errors", { errors })
          })
      )
    ).finally(() => {
      loading = false
    })
  }
</script>

<div class="milti-fs-node-selected">
  <div class="selected">{$_("selected", { values: { nb: fsNodes.length.toString() } })}</div>
  <div class="actions">
    <button class="primary" on:click={() => dispatch("multi_move")}>{$_("move")}</button>
    <button class="primary" aria-busy={loading} on:click={handleDeleteFsNodes}>{$_("delete")}</button>
  </div>
</div>

<style>
  .milti-fs-node-selected {
    word-break: break-all;
    overflow: hidden;
  }

  .selected {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
  }
</style>
