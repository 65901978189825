<script lang="ts">
  import { _ } from "svelte-i18n"
  import { link } from "svelte-navigator"

  import AddIcon from "virtual:icons/mdi/plus"

  import Loader from "../components/Loader.svelte"
  import MenuItem from "../components/MenuItem.svelte"
  import Modal from "../components/Modal.svelte"
  import Header from "../components/layout/Header.svelte"
  import Main from "../components/layout/Main.svelte"
  import SideMenu from "../components/layout/SideMenu.svelte"

  import type { Objective } from "./Objective"
  import AddObjectiveModal from "./components/AddObjectiveModal.svelte"
  import Breadcrumb from "./components/Breadcrumb.svelte"
  import MoveObjectiveModal from "./components/MoveObjectiveModal.svelte"
  import NodeItem from "./components/NodeItem.svelte"
  import ObjectiveItem from "./components/ObjectiveItem.svelte"
  import {
    changeStatusLoadingUuid,
    deleteObjectiveLoadingUuid,
    loadObjectivesLoading,
    movingLoadingUuid,
    objectivesAncestors,
    objectivesStore,
    objectivesTree,
    parentStore,
    saveObjectveLoading,
    transformObjectveLoading,
    wantEditObjective,
    wantMoveObjective
  } from "./stores/ObjectivesStore"

  export let uuid: string

  $: objectivesStore.loadObjectives(uuid, undefined, 2)

  let addObjectiveUuid: string | undefined = undefined

  function handleAddObjective(objective: Objective) {
    addObjectiveUuid = undefined
    objectivesStore.addItem(objective)
  }

  function handleChangeState(objective: Objective) {
    const state = objective.data.state === "New" ? "Completed" : "New"
    objectivesStore.changeState(objective, state)
  }

  let draged: boolean = false
  let dragedId: string | undefined = undefined
  let dragedToId: string | undefined = undefined

  function dragstart(event: any) {
    draged = true
    dragedToId = event.target.id
    dragedId = event.target.id
  }

  function dragend(event: DragEvent) {
    event.preventDefault()
    draged = false
  }

  function dragenter(event: DragEvent & { target: { id?: string } | null }) {
    event.preventDefault()
    if (event.target?.id) {
      dragedToId = event.target.id
    }
  }

  function dragleave() {
    dragedToId = undefined
  }

  function dragover(event: DragEvent) {
    event.preventDefault()
  }

  function drop() {
    if (dragedId && dragedToId && dragedId !== dragedToId) {
      move(dragedId, dragedToId)
    }
    draged = false
    dragedToId = undefined
    dragedId = undefined
  }

  function move(dragedId: string, dragedToId: string) {
    let source = $objectivesStore.find(o => o.uuid === dragedId)
    let destination = $objectivesStore.find(o => o.uuid === dragedToId)
    if (source && destination) {
      objectivesStore.move(source, destination)
    }
  }
</script>

<Header />
<SideMenu href="/objectives">
  <MenuItem label={$_("projects")} href="/objectives" />
  <MenuItem label={$_("members")} href={`/objectives/members/${uuid}`} />
</SideMenu>
<Main>
  <div class="toolbar">
    <Breadcrumb ancestors={$objectivesAncestors} />
    <div class="actions">
      <a use:link class="link" href={`/objectives/documentation/${$objectivesAncestors[0]?.uuid}`}>
        {$_("documentation")}
      </a>
      <button class="icon" on:click={() => (addObjectiveUuid = $objectivesTree?.data.uuid)}>
        <AddIcon />
      </button>
    </div>
  </div>
  {#if $loadObjectivesLoading}
    <div class="loader">
      <Loader size={40} />
    </div>
  {:else}
    {#if $objectivesTree && $parentStore}
      <ObjectiveItem
        edit={$wantEditObjective}
        objective={$parentStore}
        on:edit={() => wantEditObjective.set(true)}
        on:cancel={() => wantEditObjective.set(false)}
        on:save={({ detail }) =>
          $parentStore && objectivesStore.patchObjective($parentStore, detail).then(() => wantEditObjective.set(false))}
        saveLoading={$saveObjectveLoading}
        transformLoading={$transformObjectveLoading}
        on:transform={({ detail }) => objectivesStore.transformObjective(detail.objective, detail.kind)}
      />
    {/if}
    <div class="projects">
      {#if $objectivesTree && $parentStore}
        {#each $objectivesTree.children as node (node.data.id)}
          <NodeItem
            {node}
            topLevel
            {draged}
            {dragedId}
            {dragedToId}
            on:drop={drop}
            on:dragstart={dragstart}
            on:dragenter={dragenter}
            on:dragover={dragover}
            on:dragend={dragend}
            on:dragleave={dragleave}
            changeStatusLoadingUuid={$changeStatusLoadingUuid}
            movingLoadingUuid={$movingLoadingUuid}
            moreActionLoadingUuid={$deleteObjectiveLoadingUuid}
            on:add={({ detail }) => (addObjectiveUuid = detail.uuid)}
            on:delete={({ detail }) => objectivesStore.deleteObjective(detail)}
            on:move={({ detail }) => wantMoveObjective.set(detail)}
            on:changeState={({ detail }) => handleChangeState(detail)}
          />
        {/each}
      {/if}
    </div>
  {/if}
</Main>

{#if addObjectiveUuid}
  <Modal title={$_("addProject")} on:close={() => (addObjectiveUuid = undefined)}>
    <AddObjectiveModal parentUuid={addObjectiveUuid} on:added={({ detail }) => handleAddObjective(detail)} />
  </Modal>
{/if}
{#if $wantMoveObjective}
  <Modal title={`${$_("move")} ${$wantMoveObjective?.data.name}`} on:close={() => wantMoveObjective.set(undefined)}>
    <MoveObjectiveModal parent={$wantMoveObjective} objective={$wantMoveObjective} />
  </Modal>
{/if}

<style>
  :global(.parent) :global(h1) {
    font-size: 1.6rem;
  }

  .loader {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .projects {
    height: inherit;
    padding: 15px;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: var(--header-height);
    padding: 4px 15px;
    border-bottom: 1px solid var(--border);

    background-color: var(--bg-primary-color);
    box-shadow: 0px 0px 5px 2px #dbdbdb;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--s2);
  }

  .link {
    text-decoration: none;
    color: var(--text-primary-color);
    margin-right: 6px;
  }

  .link:hover {
    text-decoration: underline;
  }
</style>
