<script lang="ts">
  export let src: string | undefined
  export let alt: string

  let loading = true
  let imgError = false
</script>

{#if imgError || !src}
  <div class="placeholder" />
{:else}
  <div class="image">
    {#if loading}
      <slot />
    {/if}
    <img {src} {alt} on:error={() => (imgError = true)} on:load={() => (loading = false)} />
  </div>
{/if}

<style>
  .placeholder,
  img {
    min-width: var(--min-width);
    min-height: var(--min-height);
    width: var(--width);
    height: var(--height);
    max-width: var(--max-width);
    max-height: var(--max-height);
    object-fit: var(--object-fit, contain);
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: var(--min-width, 50px);
    min-height: var(--min-height, 50px);
    width: var(--width);
    height: var(--height);
    max-width: var(--max-width);
    max-height: var(--max-height);
  }

  .placeholder {
    background-color: var(--placeholder-bg, #cccccc);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
  }
</style>
