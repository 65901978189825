<script lang="ts">
  import { link } from "svelte-navigator"

  import type { Objective } from "../Objective"

  export let objective: Objective
</script>

<a use:link href={`/objectives/${objective.uuid}`}>
  <slot />
</a>

<style>
  a {
    color: var(--text-primary-color);
    text-decoration: none;
  }
</style>
