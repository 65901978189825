<div class="feed-content">
  <slot />
</div>

<style>
  :global(.feed-content) {
    font-size: 1.1em;
    line-height: 1.7em;
    hyphens: auto;
  }

  :global(.feed-content) :global(h1) {
    font-weight: bold;
    font-size: 1.6em;
  }

  :global(.feed-content) :global(h2) {
    font-weight: bold;
    font-size: 1.5em;
  }

  :global(.feed-content) :global(h3) {
    font-weight: bold;
    font-size: 1.4em;
  }

  :global(.feed-content) :global(h4) {
    font-weight: bold;
    font-size: 1.3em;
  }

  :global(.feed-content) :global(h5) {
    font-weight: bold;
    font-size: 1.2em;
  }

  :global(.feed-content) :global(h6) {
    font-weight: bold;
    font-size: 1.1em;
  }

  :global(.feed-content) :global(p),
  :global(.feed-content) :global(div),
  :global(.feed-content) :global(figure),
  :global(.feed-content) :global(img),
  :global(.feed-content) :global(iframe),
  :global(.feed-content) :global(code),
  :global(.feed-content) :global(pre),
  :global(.feed-content) :global(video),
  :global(.feed-content) :global(svg) {
    max-width: 100%;
  }

  :global(.feed-content) :global(p),
  :global(.feed-content) :global(img),
  :global(.feed-content) :global(iframe),
  :global(.feed-content) :global(code),
  :global(.feed-content) :global(pre) {
    margin: 30px 0;
  }

  :global(.feed-content) :global(p),
  :global(.feed-content) :global(code),
  :global(.feed-content) :global(pre) {
    font-size: 1.1rem;
    color: #333333;
  }

  :global(.feed-content) :global(code),
  :global(.feed-content) :global(pre) {
    padding: 7px;
    background-color: #f3f3f3;
  }

  :global(.feed-content table) {
    max-width: 100%;
    overflow-x: scroll;
  }

  :global(.feed-content) :global(table) :global(code),
  :global(.feed-content) :global(table) :global(pre) {
    padding: 2px;
    background-color: inherit;
    max-width: 100%;
    overflow-x: scroll;
  }

  :global(.feed-content figure) {
    margin: 0;
  }
  :global(.feed-content picture) {
    padding: 0;
    margin: 0;
  }

  :global(.feed-content) :global(code),
  :global(.feed-content) :global(pre) {
    max-width: 100%;
    overflow-x: scroll;
  }
</style>
