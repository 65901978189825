<script lang="ts">
  import { _ } from "svelte-i18n"
  import { link } from "svelte-navigator"

  import CloseIcon from "virtual:icons/mdi/close"

  import { drawer } from "../../stores/store"

  export let href: string
</script>

<nav class:open={$drawer}>
  <header>
    <a use:link class="app-title" {href}>{$_("title")}</a>
    <button class="close" on:click={() => drawer.toggle()}>
      <CloseIcon color="#FFFFFF" width={24} height={24} />
    </button>
  </header>
  <section>
    <slot />
  </section>
</nav>

<style>
  nav {
    width: var(--side-menu-width);
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    transition: margin var(--transition-duration) cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    background-color: var(--side-menu-bg-color);
    margin-left: calc(var(--drawer-width) * -1);
    z-index: var(--zi, 101);
  }

  nav.open {
    margin-left: 0;
  }

  header {
    width: 100%;
    height: var(--header-height);
    display: grid;
    align-items: center;
    gap: var(--s3);
    grid-template-columns: 1fr 36px;
    padding: 0 var(--s3);
    border-bottom: 1px solid var(--border);
  }

  section {
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }

  .app-title {
    font-size: 1rem;
    color: var(--color-light);
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  .close {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .close {
      display: block;
    }
  }
</style>
