import { writable } from "svelte/store"

import { Api } from "../../services/Api"
import { filterMap } from "../../utils"
import type { FsNode, UploadResult } from "../models/FsNode"
import { fsNodesStore } from "./FilesStore"

interface UploadFile {
  name: string
  size: number
  file: File
  type: string
  lastModified: number
  progress: number
  request?: Promise<void>
}

export const uploadFiles = writable<UploadFile[]>([])

export function isFsNode(value: UploadResult): value is FsNode {
  return !!(value as FsNode).uuid
}

export function upload(parentUuid: string, fileList: FileList) {
  uploadFiles.update(state => {
    for (var i = 0; i < fileList.length; i++) {
      const file: File = fileList[i]
      const formData = new FormData()
      formData.append(file.name, file)
      state.push({
        name: file.name,
        file,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        progress: 0,
        request: Api.fsNodes
          .upload(parentUuid, formData, event => progress(event, file.name))
          .then(uploadResult => fsNodesStore.concat(uploadResult.filter(isFsNode)))
      })
    }
    return state
  })
}

function progress(event: any, fileName: string) {
  const progress = Math.round((event.loaded * 100) / event.total)
  uploadFiles.update(state =>
    filterMap(
      state,
      f => ({ ...f, progress }),
      f => f.name === fileName
    )
  )
}

export function clear() {
  uploadFiles.update(state => state.filter(f => f.progress !== 100))
}
