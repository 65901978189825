<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import ReaderModeIcon from "virtual:icons/mdi/book-open"
  import RssIcon from "virtual:icons/mdi/rss"
  import StarMinusIcon from "virtual:icons/mdi/star-minus-outline"
  import StarPlusIcon from "virtual:icons/mdi/star-plus-outline"

  import FeedContent from "../../components/FeedContent.svelte"

  import { dateFormat, getRssFeedTitle, sanitize } from "../../utils"
  import type { RssFeed } from "../models/RssFeed"

  export let rssFeed: RssFeed
  export let likeLoading: boolean = false

  const dispatch = createEventDispatcher<{
    like: {
      rssFeed: RssFeed
    }
    bookmark: {
      rssFeed: RssFeed
    }
  }>()

  let readableMode: boolean = true
</script>

<article class="rss-feed">
  <a class="title" target="_blanc" href={rssFeed.rss_url}>{getRssFeedTitle(rssFeed)}</a>
  <div class="toolbar">
    <div class="date">{$_({ id: "created", values: { date: dateFormat(new Date(rssFeed.created_at)) } })}</div>
    <div class="actions">
      <button class="icon" aria-busy={likeLoading} on:click={() => dispatch("like", { rssFeed })}>
        {#if rssFeed.reaction === "Liked"}
          <StarMinusIcon />
        {:else}
          <StarPlusIcon />
        {/if}
      </button>
      <button class="icon" on:click={() => (readableMode = !readableMode)}>
        {#if readableMode}
          <RssIcon />
        {:else}
          <ReaderModeIcon />
        {/if}
      </button>
    </div>
  </div>

  <div class="content">
    <FeedContent>
      {#if readableMode && rssFeed?.readable?.content}
        {@html sanitize(rssFeed?.readable?.content)}
      {:else if !readableMode && (rssFeed.rss?.content || rssFeed.rss?.summary)}
        {@html sanitize(rssFeed.rss?.content || rssFeed.rss?.summary || "")}
      {/if}
    </FeedContent>
  </div>
</article>

<style>
  .title {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--text-primary-color);
    text-decoration: none;
  }

  .title:hover {
    text-decoration: underline;
    color: var(--text-primary-color);
  }

  .title:visited {
    color: var(--text-unimportant-color);
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
  }

  .date {
    font-size: 0.9rem;
    color: var(--text-unimportant-color);
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: var(--s1);
  }

  .content {
    padding-top: 15px;
    padding-bottom: 60px;
  }
</style>
