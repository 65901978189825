<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"
  import { link, useParams } from "svelte-navigator"

  import ArrowLefttIcon from "virtual:icons/mdi/arrow-left-thin"
  import ArrowRightIcon from "virtual:icons/mdi/arrow-right-thin"

  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import NoRssFeeds from "../components/NoRssFeeds.svelte"
  import RssFeedContent from "../components/RssFeedContent.svelte"
  import RssFeedMoreActions from "../components/RssFeedMoreActions.svelte"
  import RssSourcesByCategoriesMenu from "../components/RssSourcesByCategoriesMenu.svelte"

  import { key_action } from "../../actions/key_action"
  import type { RssFeed } from "../../rss_feeds/models/RssFeed"
  import type { RssSource } from "../../rss_feeds/models/RssSource"
  import { Api } from "../../services/Api"
  import { defaultPagination } from "../../services/Pagination"
  import {
    currentRssSource,
    markAsReadedLoading,
    nextRssFeedToShow,
    nextRssFeeds,
    previusRssFeeds
  } from "../stores/RssFeedsStore"
  import { loadRssSourcesByCategories, rssSourcesStore } from "../stores/RssSourcesStore"

  onMount(loadRssSourcesByCategories)

  const params = useParams<{
    type: string
    id?: string
  }>()

  $: rssFeedsResponse = getRssFeeds($params.type, $params.id).then(nextRssFeeds.set)

  function getRssFeeds(sourceType: string, uuid?: string): Promise<RssFeed[]> {
    if (sourceType === "source" && uuid) {
      return Api.rssFeeds.getRssFeeds("Unreaded", defaultPagination, uuid)
    } else if (sourceType === "category" && uuid) {
      return Api.rssFeeds.getRssFeeds("Unreaded", defaultPagination, undefined, uuid)
    } else {
      return Api.rssFeeds.getRssFeeds("Unreaded", defaultPagination)
    }
  }

  function reloadRssFeeds() {
    getRssFeeds($params.type, $params.id).then(nextRssFeeds.set)
  }

  let nextRssFeedLoading = false
  $: handleNextRssFeed = () => {
    if ($nextRssFeedToShow && $nextRssFeedToShow.reaction === "Unreaded") {
      nextRssFeedLoading = true
      Api.rssFeeds
        .updateRssFeed($nextRssFeedToShow, "Readed")
        .then(() => {
          if ($nextRssFeeds.length === 1) {
            getRssFeeds($params.type, $params.id)
              .then(rssFeeds => {
                if ($nextRssFeedToShow) {
                  rssSourcesStore.rssFeedsDecrement($nextRssFeedToShow.rss_source_uuid)
                }
                nextRssFeeds.concat(rssFeeds)
                nextRssFeeds.nextRssFeed()
              })
              .then(() => rssSourcesStore.reloadRssSources())
          } else if ($nextRssFeedToShow) {
            rssSourcesStore.rssFeedsDecrement($nextRssFeedToShow.rss_source_uuid)
            nextRssFeeds.nextRssFeed()
          }
          scrollTo({ top: 0 })
        })
        .finally(() => (nextRssFeedLoading = false))
    } else {
      if ($nextRssFeeds.length === 1) {
        getRssFeeds($params.type, $params.id)
          .then(rssFeeds => {
            nextRssFeeds.concat(rssFeeds)
            nextRssFeeds.nextRssFeed()
            nextRssFeedLoading = false
            scrollTo({ top: 0 })
          })
          .then(() => rssSourcesStore.reloadRssSources())
      } else {
        nextRssFeeds.nextRssFeed()
        scrollTo({ top: 0 })
      }
    }
  }

  function handlePreviusRssFeed() {
    scrollTo({ top: 0 })
    previusRssFeeds.previusRssFeed()
  }

  let likeLoading: boolean = false
  function handleReaction(rssFeed: RssFeed) {
    likeLoading = true
    const reaction = rssFeed.reaction === "Liked" ? "Readed" : "Liked"
    Api.rssFeeds
      .updateRssFeed(rssFeed, reaction)
      .then(() => {
        nextRssFeeds.updateReaction(rssFeed, reaction)
        if (rssFeed.reaction === "Unreaded" && $nextRssFeedToShow) {
          rssSourcesStore.rssFeedsDecrement($nextRssFeedToShow.rss_source_uuid)
        }
      })
      .finally(() => (likeLoading = false))
  }

  $: rssSource =
    $currentRssSource || ($params.type === "source" ? $rssSourcesStore.find(s => s.uuid === $params.id) : undefined)

  let noMoreRssFeeds = $_("noMoreRssFeeds")

  function handleMarkAsReaded(date: Date, currentRssSource: RssSource) {
    reloadRssFeeds()
    nextRssFeeds.markAsReaded(currentRssSource, date)
  }
</script>

<Header>
  <div class="actions">
    <div>
      {#if rssSource}
        <a use:link href={`/rss/feeds/source/${rssSource.uuid}`} class="current-rss-source">
          {rssSource.unreaded}
          -
          {rssSource.title}
        </a>
      {/if}
    </div>
    {#if $currentRssSource}
      <RssFeedMoreActions
        loading={$markAsReadedLoading}
        currentRssSource={$currentRssSource}
        on:mark_as_readed={({ detail }) => handleMarkAsReaded(detail.date, detail.currentRssSource)}
      />
    {/if}
  </div>
</Header>

<SideMenu href="/rss/feeds">
  <div class="menu-items">
    <MenuItem label={$_("findNewRssSources")} href="/rss/sources/find" />
    <MenuItem label={$_("myRssSources")} href="/rss/sources" />
    <MenuItem label={$_("favorites")} href="/rss/feeds/liked" />
    <MenuItem
      selected={!$params.id}
      label={$_("allCategories")}
      href="/rss/feeds"
      count={$rssSourcesStore.reduce((acc, i) => acc + i.unreaded, 0)}
    />
  </div>
  <div class="categories-menu">
    <RssSourcesByCategoriesMenu uuid={$params.id} />
  </div>
</SideMenu>

<Main>
  <div
    class="rss-feeds"
    use:key_action={{
      "ArrowRight|d|D": handleNextRssFeed,
      "ArrowLeft|q|Q": handlePreviusRssFeed
    }}
  >
    {#await rssFeedsResponse}
      <Loader size={40} />
    {:then _}
      {#if $nextRssFeedToShow}
        <RssFeedContent
          rssFeed={$nextRssFeedToShow}
          on:like={event => handleReaction(event.detail.rssFeed)}
          {likeLoading}
        />
      {:else if $rssSourcesStore.length === 0}
        <NoRssFeeds />
      {:else}
        <div class="no-more">{noMoreRssFeeds}</div>
      {/if}
    {/await}
  </div>
</Main>

<div class="rss-feed-actions" />
{#if $nextRssFeedToShow}
  <div class="rss-feed-next">
    <button class="button" on:click={handleNextRssFeed} aria-busy={nextRssFeedLoading}>
      <ArrowRightIcon width={40} height={40} color="#FFFFFF" />
    </button>
  </div>
{/if}
{#if $previusRssFeeds.length > 0}
  <div class="rss-feed-previus">
    <button class="button" on:click={handlePreviusRssFeed}>
      <ArrowLefttIcon width={40} height={40} color="#FFFFFF" />
    </button>
  </div>
{/if}

<style>
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .current-rss-source {
    font-size: 0.9rem;
    color: var(--text-unimportant-color);
    text-decoration: none;
  }

  .current-rss-source:hover {
    text-decoration: underline;
  }

  .rss-feed-next {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--bg-secondary-color);
    border-radius: 25px;
    overflow: hidden;
  }

  .rss-feed-previus {
    position: fixed;
    bottom: 20px;
    right: 90px;
    background-color: var(--bg-secondary-color);
    border-radius: 25px;
    overflow: hidden;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 0;
  }

  .rss-feeds {
    width: 100%;
    padding: 25px 15px;
  }

  .menu-items {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border);
  }

  .categories-menu {
    flex: 1;
    overflow-y: auto;
  }

  @media screen and (min-width: 900px) {
    .rss-feeds {
      width: 900px;
      margin: auto;
    }
  }

  .no-more {
    color: var(--text-unimportant-color);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
  }
</style>
