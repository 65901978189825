<script lang="ts">
  import Badge from "../../ui_kit/Badge.svelte"
  import type { Member } from "../Member"

  export let member: Member
</script>

<div class="member">
  <div class="user-photo">
    {member.login.substring(0, 2).toUpperCase()}
  </div>
  <div class="login">
    {member.login}
    <Badge label={member.member_type} --ml="6px" />
  </div>
  <Badge label={member.member_status} />
</div>

<style>
  .member {
    padding: var(--s3);
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--border);
    margin-bottom: var(--mb, var(--s3));
  }

  .login {
    flex: 1;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    margin-left: var(--s3);
  }

  .user-photo {
    --size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    background-color: var(--bg-accent-color);
    color: #fff;
  }
</style>
