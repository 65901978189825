<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import type { Member } from "../Member"

  export let member: Member
  export let acceptLoading: boolean
  export let declineLoading: boolean

  const dispatch = createEventDispatcher<{
    accept: undefined
    decline: undefined
  }>()
</script>

<div class="invitation">
  <div class="title">
    {$_("member.invitation")}
    <span class="project-name">
      {member.project_name}
    </span>
  </div>
  <div class="actions">
    <button class="primary" on:click={() => dispatch("accept")} aria-busy={acceptLoading}>{$_("accept")}</button>
    <button class="primary" on:click={() => dispatch("decline")} aria-busy={declineLoading}>{$_("decline")}</button>
  </div>
</div>

<style>
  .invitation {
    padding: var(--s3);
    background-color: var(--bg-primary-color);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    transition: all var(--transition-duration);
    border-radius: 3px;
    margin-bottom: var(--mb, var(--s3));
  }

  .project-name {
    font-weight: bold;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--s3);
  }
</style>
