<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { FsNode, getFsNodeName } from "../models/FsNode"
  import { fsNodesStore, wantMoveFsNode, wantRenameFsNode } from "../stores/FilesStore"

  export let fsNode: FsNode
  export let unselected = false

  let loading = false

  const dispatch = createEventDispatcher<{
    title_click: FsNode
    deleted: {
      fsNode: FsNode
    }
    errors: {
      errors: ApiErrors
    }
  }>()

  $: handleDeleteFsNode = () => {
    loading = true
    fsNodesStore
      .deleteFsNodeAction(fsNode.uuid)
      .then(() => {
        dispatch("deleted", { fsNode })
      })
      .catch(errors => {
        dispatch("errors", { errors })
      })
      .finally(() => (loading = false))
  }

  $: name = getFsNodeName(fsNode.data)
</script>

<div class="single-fs-node-selected">
  {#if name}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div on:click={() => dispatch("title_click", fsNode)} class="name">
      {name}
    </div>
  {/if}

  {#if fsNode.data.type === "Image"}
    <div class="thumbnail"><img src={Api.fsNodes.getThumbnailUri(fsNode.uuid, 300, 300)} alt="thumbnail" /></div>
  {/if}

  <div class="actions">
    {#if !unselected && fsNode.data.type !== "Root" && fsNode.data.type !== "Bin"}
      <button class="primary" on:click={() => wantMoveFsNode.set(fsNode)}>{$_("move")}</button>
    {/if}
    {#if (!unselected && fsNode.data.type === "File") || fsNode.data.type === "Image" || fsNode.data.type === "Directory" || fsNode.data.type === "Note"}
      <button class="primary" on:click={() => wantRenameFsNode.set(fsNode)}>{$_("rename")}</button>
    {/if}
    {#if (!unselected && fsNode.data.type === "File") || fsNode.data.type === "Image"}
      <a class="button primary" download={name} href={Api.fsNodes.getDownloadUri(fsNode.uuid, "Download")}>
        {$_("download")}
      </a>
    {/if}
    {#if !unselected && fsNode.data.type !== "Root" && fsNode.data.type !== "Bin"}
      <button class="primary" aria-busy={loading} on:click={handleDeleteFsNode}>{$_("delete")}</button>
    {/if}
  </div>
</div>

<style>
  .single-fs-node-selected {
    word-break: break-all;
    overflow: hidden;
  }

  .name {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .name:hover {
    text-decoration: underline;
  }

  .thumbnail {
    width: 200px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
  }
</style>
