<script lang="ts">
  import { _ } from "svelte-i18n"
  import { navigate } from "svelte-navigator"

  import { tokenStore } from "../account/TokenStore"
  import LoginForm from "../account/components/LoginForm.svelte"
  import type { Login } from "../account/models/Login"
  import { Api } from "../services/Api"
  import type { ApiErrors } from "../services/ApiError"

  let loading: boolean = false
  let loginErrors: ApiErrors | undefined = undefined

  function submit(login: Login) {
    loading = true
    Api.auth
      .login(login, "rss_feeds files bookmarks objectives admin")
      .then(() => {
        tokenStore.reinit()
        navigate("/admin", { replace: true })
      })
      .catch((errors: ApiErrors) => (loginErrors = errors))
      .finally(() => (loading = false))
  }
</script>

<main class="main">
  <h1>{$_("title")} Admin</h1>
  <LoginForm {loading} {loginErrors} on:submit={({ detail }) => submit(detail)} />
</main>

<style>
  h1 {
    font-size: 2rem;
    color: var(--text-primary-color);
    font-weight: bold;
  }

  .main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
</style>
