<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { key_action } from "../../actions/key_action"

  export let initialName: string | undefined = undefined
  let name = initialName || ""

  const dispatch = createEventDispatcher<{
    save: {
      name: string
    }
    cancel: undefined
  }>()
</script>

<div class="fs-node">
  <div class="icon">
    <slot />
  </div>
  <!-- svelte-ignore a11y-autofocus -->
  <input autofocus type="text" bind:value={name} />
  <div class="actions" use:key_action={{ Enter: () => dispatch("save", { name }), Escape: () => dispatch("cancel") }}>
    <button class="primary" on:click={() => dispatch("save", { name })}>{$_("save")}</button>
    <button class="primary" on:click={() => dispatch("cancel")}>{$_("cancel")}</button>
  </div>
</div>

<style>
  .fs-node {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #ffffff;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
  }

  .actions {
    display: flex;
    gap: var(--s2);
    padding-right: var(--s3);
  }

  input {
    flex: 1;
    padding: 5px;
    margin-right: var(--s3);
    color: var(--text-primary-color);
  }

  @media screen and (max-width: 700px) {
    .actions {
      display: flex;
      flex: 1;
      justify-content: end;
      padding-bottom: 5px;
    }
  }
</style>
