<script lang="ts">
  import { _ } from "svelte-i18n"

  import TaskIcon from "virtual:icons/clarity/tasks-line"
  import BugIcon from "virtual:icons/mdi/bug-outline"
  import TasksIcon from "virtual:icons/mdi/file-tree"

  import Loader from "../../components/Loader.svelte"

  import { Api } from "../../services/Api"
  import type { Objective } from "../Objective"
  import { objectivesStore, wantMoveObjective } from "../stores/ObjectivesStore"
  import NavigatorBreadcrumb from "./NavigatorBreadcrumb.svelte"

  export let objective: Objective
  export let parent: Objective

  let target: Objective = parent
  let loading = false

  $: objectiveResponse = Api.objectives.getObjectives(target.uuid, undefined, 1)

  $: handleMove = () => {
    if (parent.uuid === target.uuid) {
      wantMoveObjective.set(undefined)
    } else {
      loading = true
      Api.objectives
        .moveObjective({ source_uuid: objective.uuid, destination_uuid: target.uuid })
        .then(() => {
          objectivesStore.move(objective, target)
          wantMoveObjective.set(undefined)
        })
        .finally(() => (loading = false))
    }
  }
</script>

<div class="move-node-modal">
  {#await objectiveResponse}
    <div class="navigator">
      <Loader />
    </div>
  {:then response}
    <div class="navigator">
      <NavigatorBreadcrumb ancestors={response.ancestors} on:click={({ detail }) => (target = detail)} />
      <div class="children">
        {#if response.childrens.length === 0}
          <div class="empty">{$_("empty")}</div>
        {/if}
        {#each response.childrens as o (o.uuid)}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div class="objective" on:click={() => (target = o)}>
            {#if o.data.type === "Story" || o.data.type === "ProjectRoot"}
              <TasksIcon color="#245b48" />
            {:else if o.data.type === "Bug"}
              <BugIcon color="#931a1a" />
            {:else if o.data.type === "Task"}
              <TaskIcon color="#d2cd53" />
            {/if}
            <div class="name">{o.data.name}</div>
          </div>
        {/each}
      </div>
    </div>
  {/await}
  <div class="actions">
    <button class="primary" on:click={handleMove} aria-busy={loading}>{$_("move")}</button>
  </div>
</div>

<style>
  .move-node-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 15px 15px 15px;
    border-top: 1px solid var(--border);
  }

  .navigator {
    overflow-y: auto;
    height: calc(100vh - 30em);
  }

  .children {
    margin-top: 16px;
  }

  .objective {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0;
    cursor: pointer;
    flex-wrap: nowrap;
  }

  .name {
    margin-left: 10px;
    color: var(--text-primary-color);
    text-decoration: underline;
  }

  .actions {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .empty {
    padding: 15px;
    color: var(--text-primary-color);
  }
</style>
