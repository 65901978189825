<script lang="ts">
  import { link } from "svelte-navigator"

  export let label: string
  export let href: string | undefined = undefined

  let size = 24
</script>

{#if href}
  <a class="item" {href} on:click use:link>
    <slot {size} />
    <div class="content">
      <div class="label">{label}</div>
    </div>
  </a>
{:else}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="item" on:click>
    <slot {size} />
    <div class="content">
      <div class="label">{label}</div>
    </div>
  </div>
{/if}

<style>
  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    transition: all var(--transition-duration);
    text-decoration: none;
    color: var(--text-accent-color);
  }

  .item:hover {
    background-color: var(--border);
  }

  .label {
    color: var(--text-accent-color);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .content {
    width: 100%;
    padding: 0 10px;
  }
</style>
