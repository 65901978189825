<script lang="ts">
  import { _ } from "svelte-i18n"
  import { link } from "svelte-navigator"
</script>

<div class="no-more">
  <div class="no-rss-feeds">{$_("noRssFeeds")}</div>
  <a use:link class="find-rss-sources" href="/rss/sources/find">
    {$_("findNewRssSources")}
  </a>
</div>

<style>
  .no-more {
    color: var(--text-unimportant-color);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
  }

  .no-rss-feeds {
    margin-bottom: 16px;
  }

  .find-rss-sources {
    text-decoration: none;
    color: var(--text-primary-color);
  }

  .find-rss-sources:hover {
    text-decoration: underline;
  }
</style>
