import { number, object, string } from "idonttrustlikethat"

import { indefinable } from "../../utils"

export const readableValidator = object({
  url: indefinable(string),
  domain: indefinable(string),
  title: indefinable(string),
  content: indefinable(string),
  date_published: indefinable(string),
  lead_image_url: indefinable(string),
  dek: indefinable(string),
  excerpt: indefinable(string),
  word_count: indefinable(number),
  direction: indefinable(string),
  total_pages: indefinable(number),
  rendered_pages: indefinable(number),
  next_page_url: indefinable(string)
})

export type Readable = typeof readableValidator.T
