<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"

  export let loading: boolean = false

  const dispatch = createEventDispatcher<{
    create_directory: undefined
    create_note: undefined
  }>()
</script>

<Dropdown right>
  <svelte:fragment slot="action">
    <button class="primary" aria-busy={loading}>{$_("create")}</button>
  </svelte:fragment>
  <svelte:fragment slot="items">
    <DropdownItem label={$_("createDirectory")} on:click={() => dispatch("create_directory")} />
    <DropdownItem label={$_("createNote")} on:click={() => dispatch("create_note")} />
  </svelte:fragment>
</Dropdown>
