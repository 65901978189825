<script lang="ts">
  import { filesize } from "filesize"

  import { createEventDispatcher } from "svelte"
  import { _, getLocaleFromNavigator } from "svelte-i18n"

  import Checkbox from "../../components/Checkbox.svelte"

  import { FsNode, getFsNodeExtension, getFsNodeName, isFsNodeFile, isFsNodeImage } from "../models/FsNode"
  import FsNodeIcon from "./FsNodeIcon.svelte"

  export let fsNode: FsNode
  export let thumbnailUri: string
  export let selected: boolean

  export let iconSize: number = 26

  const dispatch = createEventDispatcher<{ select: FsNode; delete: FsNode; download: FsNode; click: FsNode }>()

  let name = getFsNodeName(fsNode.data)
  let extension = getFsNodeExtension(fsNode.data)
</script>

<div class="fs-node" class:selected style={`--icon-size: ${iconSize}px`}>
  <Checkbox checked={selected} on:change={() => dispatch("select", fsNode)} />
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="icon" on:click={() => dispatch("click", fsNode)}>
    <FsNodeIcon {fsNode} {thumbnailUri} width={26} height={26} />
  </div>
  {#if name}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <p class="name" on:click={() => dispatch("click", fsNode)}>
      {name}{extension ?? ""}
    </p>
  {/if}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="size" on:click={() => dispatch("click", fsNode)}>
    {#if isFsNodeFile(fsNode.data) || isFsNodeImage(fsNode.data)}
      {filesize(fsNode.data.size, { locale: getLocaleFromNavigator()?.toString() })}
    {/if}
  </div>
  <div class="actions">
    <slot />
  </div>
</div>

<style>
  .fs-node {
    display: grid;
    grid-template-columns: 26px var(--icon-size, 26px) 1fr auto auto;
    min-height: 35px;
    border: 1px solid #ffffff;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
    transition-duration: var(--transition-duration);
    transition-property: border-color;
  }

  .fs-node:hover {
    border: 1px solid #0277bd;
  }

  .selected {
    background-color: #b3e5fc;
    border: 1px solid #b3e5fc;
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: center;
    height: var(--icon-size, 26px);
    align-items: center;
    cursor: pointer;
  }

  .name {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 0 9px;
    margin: 0;

    font-size: 0.9rem;
    color: var(--text-primary-color);
    word-break: break-all;
    overflow: hidden;
  }

  .size {
    color: var(--text-unimportant-color);
    display: none;
    font-size: 0.7rem;
    padding: 0 5px;
    cursor: pointer;
  }

  /* Mobile first tablet breakpoint */
  @media screen and (min-width: 640px) {
    .fs-node {
      grid-template-columns: 26px var(--icon-size, 26px) 1fr auto auto;
      margin-bottom: 0;
    }
    .name {
      font-size: 1rem;
    }
    .size {
      display: block;
      font-size: 0.8rem;
    }
  }
</style>
