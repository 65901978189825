<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { getFieldErrorMessage } from "../../utils"
  import type { RssSourceCategory } from "../models/RssSourceCategory"

  let loading = false
  let deleteLoading = false
  let requestErrors: ApiErrors | undefined = undefined

  export let rssSourceCategory: RssSourceCategory
  let title = rssSourceCategory.title

  $: titleError = getFieldErrorMessage("title", (k, p) => $_(k, p), requestErrors)

  function submitEdit() {
    loading = true
    Api.rssSourcesCategories
      .updateRssSourceCategory(title, rssSourceCategory)
      .then(updatedRssSourceCategory => {
        dispatch("updated", { rssSourceCategory: updatedRssSourceCategory })
        loading = false
      })
      .catch((errors: ApiErrors) => {
        requestErrors = errors
        loading = false
      })
  }

  function submitDelete() {
    deleteLoading = true
    Api.rssSourcesCategories
      .deleteRssSourceCategory(rssSourceCategory)
      .then(() => {
        dispatch("deleted", { rssSourceCategory })
        deleteLoading = false
      })
      .catch((errors: ApiErrors) => {
        requestErrors = errors
        deleteLoading = false
      })
  }

  const dispatch = createEventDispatcher<Message>()

  interface Message {
    updated: {
      rssSourceCategory: RssSourceCategory
    }
    deleted: {
      rssSourceCategory: RssSourceCategory
    }
  }
</script>

<div class="modal">
  <label for="title">
    {$_("form.title")}
    <input id="title" type="text" bind:value={title} />
    {#if titleError}
      <div class="error">{titleError}</div>
    {/if}
  </label>
  <div class="actions">
    <button class="primary" aria-busy={loading} on:click={submitEdit}>{$_("edit")}</button>
    <button aria-busy={deleteLoading} on:click={submitDelete}>{$_("delete")}</button>
  </div>
</div>

<style>
  .modal {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
    padding: 0 var(--s3);
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: var(--s3);
    justify-content: space-between;
  }
</style>
