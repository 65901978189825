<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import { key_action } from "../../actions/key_action"
  import type { ApiErrors } from "../../services/ApiError"
  import { getFieldErrorMessage } from "../../utils"
  import type { Login } from "../models/Login"

  let username: string = ""
  let password: string = ""

  export let loading = false
  export let loginErrors: ApiErrors | undefined = undefined

  $: emailError = getFieldErrorMessage("username", (k, values) => $_(k, { values }), loginErrors)
  $: passwordError = getFieldErrorMessage("password", (k, values) => $_(k, { values }), loginErrors)

  const dispatch = createEventDispatcher<{
    submit: Login
  }>()

  function handleSubmit() {
    dispatch("submit", { username, password })
  }
</script>

<div class="form" use:key_action={{ Enter: handleSubmit }}>
  <label for="email">
    {$_("auth.email")}
    <input id="email" type="email" bind:value={username} />
    {#if emailError}
      <div class="error">{emailError}</div>
    {/if}
  </label>

  <label for="password">
    {$_("auth.password")}
    <input id="password" type="password" bind:value={password} />
    {#if passwordError}
      <div class="error">{passwordError}</div>
    {/if}
  </label>

  <button class="primary" aria-busy={loading} on:click={handleSubmit}>
    {$_("auth.login")}
  </button>
</div>

<style>
  .form {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: var(--s5);
  }

  @media screen and (min-width: 500px) {
    .form {
      width: 400px;
    }
  }
</style>
