<script lang="ts">
  import { _ } from "svelte-i18n"
  import { link, navigate } from "svelte-navigator"

  import { tokenStore } from "../account/TokenStore"
  import { Api } from "../services/Api"
  import type { ApiErrors } from "../services/ApiError"
  import SignupForm from "./components/SignupForm.svelte"
  import type { Signup } from "./models/Signup"

  let loading: boolean = false
  let loginErrors: ApiErrors | undefined = undefined

  function submit(signup: Signup) {
    loading = true
    Api.auth
      .signup(signup)
      .then(() => {
        tokenStore.reinit()
        navigate("/", { replace: true })
      })
      .catch((errors: ApiErrors) => (loginErrors = errors))
      .finally(() => (loading = false))
  }
</script>

<main class="main">
  <h1>{$_("title")}</h1>
  <SignupForm {loading} {loginErrors} on:submit={({ detail }) => submit(detail)} />
  <a use:link href="/login">{$_("auth.login")}</a>
</main>

<style>
  a {
    margin-top: 30px;
    font-size: 1rem;
    color: var(--text-primary-color);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  h1 {
    font-size: 2rem;
    color: var(--text-primary-color);
    font-weight: bold;
  }

  .main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
</style>
