<script lang="ts">
  import type { FsNode } from "../models/FsNode"
  import { fsNodesStore, selectedFsNodes, wantMoveMultiFsNodes } from "../stores/FilesStore"
  import MultiFsNodeSelected from "./MultiFsNodeSelected.svelte"
  import SingleFsNodeSelected from "./SingleFsNodeSelected.svelte"

  export let parent: FsNode
</script>

{#if $selectedFsNodes.length == 0}
  <SingleFsNodeSelected on:title_click unselected fsNode={parent} />
{/if}
{#if $selectedFsNodes.length == 1}
  <SingleFsNodeSelected
    on:title_click
    on:deleted={event => {
      fsNodesStore.removeItem(event.detail.fsNode)
      selectedFsNodes.select(event.detail.fsNode)
    }}
    fsNode={$selectedFsNodes[0]}
  />
{/if}
{#if $selectedFsNodes.length > 1}
  <MultiFsNodeSelected
    fsNodes={$selectedFsNodes}
    on:deleted={event => {
      fsNodesStore.removeItem(event.detail.fsNode)
      selectedFsNodes.select(event.detail.fsNode)
    }}
    on:multi_move={() => wantMoveMultiFsNodes.set($selectedFsNodes)}
  />
{/if}
