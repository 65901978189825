<script lang="ts">
  import { _ } from "svelte-i18n"

  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import Breadcrumb from "../components/Breadcrumb.svelte"
  import FlyingPanel from "../components/FlyingPanel.svelte"
  import FsNodeSelection from "../components/FsNodeSelection.svelte"
  import FsNodes from "../components/FsNodes.svelte"

  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { fsNodesStore, selectedFsNodes } from "../stores/FilesStore"

  export let uuid: string | undefined

  $: filesResponse = Api.fsNodes.getFiles(uuid, "Bin").then(response => {
    fsNodesStore.set(response.childrens)
    return response
  })

  let cleanupLoading = false
  function cleanup() {
    cleanupLoading = true
    Api.fsNodes
      .cleanup()
      .then(() => {
        cleanupLoading = false
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
        cleanupLoading = false
      })
  }
</script>

<Header />

<SideMenu href="/fs">
  <MenuItem label={$_("bin")} href="/fs/trash-bin" />
</SideMenu>

<Main>
  <div class="main">
    {#await filesResponse}
      <Loader size={40} />
    {:then files}
      <div class="content">
        <div class="tools">
          <Breadcrumb ancestors={files.ancestors} />
          <div class="actions">
            <button class="primary" aria-busy={cleanupLoading} on:click={cleanup}>{$_("cleanup")}</button>
          </div>
        </div>
        <FsNodes parentUuid={files.fs_node.uuid} />
      </div>
      <FlyingPanel selected={$selectedFsNodes.length > 0} on:click={() => selectedFsNodes.deselect()}>
        <FsNodeSelection parent={files.fs_node} />
      </FlyingPanel>
    {:catch error}
      <p style="color: red">{error.message}</p>
    {/await}
  </div>
</Main>

<style>
  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .tools {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .actions {
    display: flex;
    padding: 15px;
  }

  .main {
    height: calc(100vh - var(--header-height));
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-template-areas:
      "content"
      "side-panel";
  }

  @media screen and (min-width: 700px) {
    .main {
      grid-template-rows: auto;
      grid-template-columns: auto 300px;
      grid-template-areas: "content side-panel";
    }
  }
</style>
