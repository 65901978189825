<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import DeleteForeverIcon from "virtual:icons/mdi/delete-forever-outline"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"

  import type { RssSource } from "../models/RssSource"
  import type { RssSourceCategory } from "../models/RssSourceCategory"

  export let rssSourcesCategories: RssSourceCategory[]
  export let rssSource: RssSource
  export let changeRssSourceCategoryLoading: boolean = false
  export let unfollowRssSourceLoading: boolean = false

  $: rssSourcesCategory = rssSourcesCategories.find(c => c.uuid === rssSource.rss_source_category_uuid)
  $: title = (rssSourcesCategory && rssSourcesCategory.title) || ""

  const dispatch = createEventDispatcher<{
    change_category: {
      rssSourceCategory: RssSourceCategory
      rssSource: RssSource
    }
    unfollow: RssSource
  }>()
</script>

<div class="rss-source">
  <div class="title">{rssSource.title}</div>
  <div class="actions">
    <Dropdown>
      <svelte:fragment slot="action">
        <button class="primary" aria-busy={changeRssSourceCategoryLoading}>{title}</button>
      </svelte:fragment>
      <svelte:fragment slot="items">
        {#each rssSourcesCategories as rssSourceCategory (rssSourceCategory.uuid)}
          <DropdownItem
            label={rssSourceCategory.title}
            on:click={() => dispatch("change_category", { rssSource, rssSourceCategory })}
          />
        {/each}
      </svelte:fragment>
    </Dropdown>
    <button class="icon" aria-busy={unfollowRssSourceLoading} on:click={() => dispatch("unfollow", rssSource)}>
      <DeleteForeverIcon />
    </button>
  </div>
</div>

<style>
  .rss-source {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid var(--border);
  }

  .actions {
    display: inline-flex;
    align-items: center;
    gap: var(--s3);
  }
</style>
