import { derived, writable } from "svelte/store"

import { Api } from "../../services/Api"
import { maxPagination } from "../../services/Pagination"
import { createArrayWritableStore, filterMap } from "../../utils"
import type { RssSource } from "../models/RssSource"
import type { RssSourceByCategory } from "../models/RssSourceByCategory"
import type { RssSourceCategory } from "../models/RssSourceCategory"

function createRssSourcesStore() {
  const store = createArrayWritableStore<RssSource>([], b => b.uuid)
  return {
    ...store,
    loadRssSources,
    reloadRssSources,
    changeRssSourceCategory,
    unfollowRssSource,
    rssFeedsDecrement: (uuid: string, step: number = 1) => {
      store.update(state =>
        filterMap(
          state,
          s => ({ ...s, unreaded: s.unreaded - step < 0 ? 0 : s.unreaded - step }),
          s => s.uuid === uuid
        )
      )
    }
  }
}
export const rssSourcesStore = createRssSourcesStore()

function createRssSourcesCategoriesStore() {
  const store = createArrayWritableStore<RssSourceCategory>([], b => b.uuid)
  return {
    ...store,
    loadRssSourceCategories
  }
}

export const rssSourcesCategoriesStore = createRssSourcesCategoriesStore()

export const loadRssSourcesLoading = writable(false)
function loadRssSources() {
  loadRssSourcesLoading.set(true)
  return Api.rssSources
    .loadRssSources(maxPagination)
    .then(rssSourcesStore.set)
    .finally(() => loadRssSourcesLoading.set(false))
}

function reloadRssSources() {
  setTimeout(() => Api.rssSources.loadRssSources(maxPagination).then(rssSourcesStore.set), 1000)
}

export const loadRssSourcesCategoriesLoading = writable(false)
function loadRssSourceCategories() {
  loadRssSourcesCategoriesLoading.set(true)
  return Api.rssSourcesCategories
    .getRssSourceCategories()
    .then(rssSourcesCategoriesStore.set)
    .finally(() => loadRssSourcesCategoriesLoading.set(false))
}

export function loadRssSourcesByCategories() {
  return Promise.all([loadRssSources(), loadRssSourceCategories()])
}

export const rssSourcesByCategories = derived(
  [rssSourcesStore, rssSourcesCategoriesStore],
  ([rssSources, rssSourcesCategories]): RssSourceByCategory[] =>
    rssSourcesCategories.map(rssSourceCategory => ({
      rssSourceCategory,
      rssSources: rssSources.filter(s => s.rss_source_category_uuid === rssSourceCategory.uuid)
    }))
)

export const changeRssSourceCategoryLoading = writable(false)
function changeRssSourceCategory(rssSource: RssSource, rssSourceCategory: RssSourceCategory) {
  changeRssSourceCategoryLoading.set(true)
  return Api.rssSourcesCategories
    .changeRssSourceCategory(rssSource, rssSourceCategory)
    .then(() => rssSourcesStore.updateItem({ ...rssSource, rss_source_category_uuid: rssSourceCategory.uuid }))
    .finally(() => changeRssSourceCategoryLoading.set(false))
}

export const unfollowRssSourceLoading = writable<string | undefined>(undefined)
function unfollowRssSource(rssSource: RssSource) {
  unfollowRssSourceLoading.set(rssSource.uuid)
  Api.rssSources
    .unfollowRssSource(rssSource)
    .then(rssSourcesStore.removeItem)
    .finally(() => unfollowRssSourceLoading.set(undefined))
}
