import { writable } from "svelte/store"

import { Api } from "../../services/Api"
import type { ApiErrors } from "../../services/ApiError"
import { defaultPagination } from "../../services/Pagination"
import { createArrayWritableStore } from "../../utils"
import type { Objective } from "../Objective"

function createProjectsStore() {
  const store = createArrayWritableStore<Objective>([], b => b.uuid)
  return {
    ...store,
    loadProjects,
    deleteProject,
    reloadProjects
  }
}

export const projectsStore = createProjectsStore()
export const projectsError = writable<ApiErrors | undefined>(undefined)

export const loadProjectsLoading = writable(false)
function loadProjects() {
  projectsError.set(undefined)
  loadProjectsLoading.set(true)
  return Api.objectives
    .getProjects(defaultPagination)
    .then(projectsStore.set)
    .catch(projectsError.set)
    .finally(() => loadProjectsLoading.set(false))
}

function reloadProjects() {
  projectsError.set(undefined)
  return Api.objectives.getProjects(defaultPagination).then(projectsStore.set).catch(projectsError.set)
}

export const deleteProjectLoading = writable<string | undefined>(undefined)
function deleteProject(objective: Objective) {
  deleteProjectLoading.set(objective.uuid)
  Api.objectives
    .deleteObjective(objective)
    .then(() => projectsStore.removeItem(objective))
    .finally(() => deleteProjectLoading.set(undefined))
}
