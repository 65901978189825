<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"
  import Loader from "../../components/Loader.svelte"
  import Markdown from "../../components/Markdown.svelte"
  import ProgressBar from "../../components/ProgressBar.svelte"

  import type { Objective, ObjectiveType, PatchObjective } from "../Objective"
  import ObjectiveTypeIcon from "./ObjectiveTypeIcon.svelte"
  import ObjectiveTypeLabel from "./ObjectiveTypeLabel.svelte"

  export let objective: Objective
  export let saveLoading = false
  export let transformLoading = false
  export let edit = false

  let name = objective.data.name
  let description = objective.data.description || ""
  let cost = objective.data.cost
  let value = objective.data.value

  let expanded = (objective.data.description?.length ?? 0) < 500

  const dispatch = createEventDispatcher<{
    save: PatchObjective
    cancel: undefined
    edit: undefined
    transform: {
      objective: Objective
      kind: ObjectiveType
    }
  }>()

  function handleEdit() {
    edit
      ? dispatch("save", {
          name: {
            type: "Replace",
            value: name || ""
          },
          description: {
            type: "Replace",
            value: description || ""
          },
          cost: cost
            ? {
                type: "Replace",
                value: cost
              }
            : {
                type: "Delete"
              },
          value: value
            ? {
                type: "Replace",
                value: value
              }
            : {
                type: "Delete"
              }
        })
      : dispatch("edit")
  }

  let transformMap: Record<ObjectiveType, ObjectiveType[]> = {
    Task: ["Bug"],
    Bug: ["Task"],
    Story: [],
    ProjectRoot: []
  }

  $: percent = Math.floor(((objective.data.completed || 0) * 100) / (objective.data.cost || 1)) || 0
</script>

<div class="container">
  <div class="content">
    <div class="header">
      {#if edit}
        <label>
          {$_("form.title")}
          <input bind:value={name} />
        </label>
      {:else}
        <h1 class="title">{objective.data.name}</h1>
      {/if}
      <div class="progress">
        {#if objective.data.type === "Story" || objective.data.type === "ProjectRoot"}
          <ProgressBar {percent} />
        {/if}
      </div>
    </div>

    <div class="type">
      <Dropdown>
        <svelte:fragment slot="action">
          <ObjectiveTypeLabel
            disabled={transformMap[objective.data.type].length === 0}
            objectiveType={objective.data.type}
          />
          {#if transformLoading}
            <Loader />
          {/if}
        </svelte:fragment>
        <svelte:fragment slot="items">
          {#each transformMap[objective.data.type] as kind}
            <DropdownItem label={$_(kind)} on:click={() => dispatch("transform", { objective, kind })}>
              <ObjectiveTypeIcon objectiveType={kind} />
            </DropdownItem>
          {/each}
        </svelte:fragment>
      </Dropdown>
    </div>

    {#if edit && !objective.data.has_children}
      <label for="cost">
        {$_("cost")}
        <input id="cost" type="number" min={0} bind:value={cost} />
      </label>
    {:else}
      <div class="field">
        <div class="label">{$_("cost")}</div>
        <div class="value">{objective.data.cost ? objective.data.cost : "-"}</div>
      </div>
    {/if}
    {#if edit}
      <label for="value">
        {$_("value")}
        <input id="value" type="number" min={0} bind:value />
      </label>
    {:else}
      <div class="field">
        <div class="label">{$_("value")}</div>
        <div class="value">{objective.data.value ? objective.data.value : "-"}</div>
      </div>
    {/if}

    {#if edit}
      <label for="description">
        {$_("description")}
        <textarea id="description" bind:value={description} />
      </label>
    {:else}
      <div class="preview">
        <div class="label">{$_("description")}</div>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="description" class:expanded={!expanded} on:click={() => (expanded = true)}>
          <Markdown source={(edit ? description : objective.data.description) || ""} />
        </div>
      </div>
    {/if}
  </div>
  <div class="actions">
    <button class="primary" aria-busy={saveLoading} on:click={handleEdit}>{edit ? $_("save") : $_("edit")}</button>
    {#if edit}
      <button class="primary" on:click={() => dispatch("cancel")}>{edit ? $_("cancel") : $_("edit")}</button>
    {/if}
  </div>
</div>

<style>
  .container {
    border-bottom: 1px solid var(--border);
    padding: var(--s3);
    display: flex;
    flex-direction: column;
    gap: var(--s3);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--s3);
  }

  .preview {
    flex: 1;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--s3);
  }

  .title {
    font-size: 1.1rem;
    padding: 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  .expanded {
    cursor: pointer;
    position: relative;
    max-height: 100px;
    overflow: hidden;
  }

  .expanded::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
</style>
