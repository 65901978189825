<script lang="ts">
  import { click_outside } from "../actions/click_outside"

  export let right: boolean | undefined = false

  let open: boolean = false
  let actionHeight: number
</script>

<div
  class="dropdown"
  class:open
  style={`--top: ${actionHeight}px`}
  bind:clientHeight={actionHeight}
  use:click_outside={() => (open = false)}
>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="action" on:click={() => (open = !open)}>
    <slot name="action" />
  </div>
  {#if open}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="items" on:click={() => (open = false)} class:items-right={right} class:items-left={!right}>
      <slot name="items" />
    </div>
  {/if}
</div>

<style>
  .dropdown {
    display: inline-block;
    margin-left: var(--ml, 0);
    margin-right: var(--mr, 0);
  }

  .open {
    position: relative;
  }

  .items {
    position: absolute;
    border-radius: 2px;
    background-color: var(--bg-primary-color);
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    z-index: var(--zi, 20);
    transform-origin: 0 0;
    max-width: 450px;
    transform: translate3d(0px, var(--top) px, 0px);
  }

  .items-right {
    right: 0;
  }

  .items-left {
    left: 0;
  }

  .action {
    cursor: pointer;
  }
</style>
