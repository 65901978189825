<script lang="ts">
  import { createEventDispatcher } from "svelte"

  import Markdown from "../../components/Markdown.svelte"

  import type { NoteData } from "../models/FsNode"
  import NoteTitleEditor from "./NoteTitleEditor.svelte"

  export let note: NoteData

  let content = note.content
  $: rows = Math.max(10, content.split("\n").length)

  const dispatch = createEventDispatcher<{
    edit_content: string
  }>()
</script>

<div class="note-preview">
  <div class="title-editor">
    <NoteTitleEditor {note} on:edit_name />
  </div>
  <div class="content-editor">
    <div class="editor">
      <textarea
        bind:value={content}
        {rows}
        on:blur={() => {
          if (note.content !== content) {
            dispatch("edit_content", content)
          }
        }}
      />
    </div>
    <div class="preview">
      <Markdown source={content} />
    </div>
  </div>
</div>

<style>
  .title-editor {
    margin-bottom: var(--s3);
  }
  .note-preview {
    padding: var(--s3);
  }

  .content-editor {
    display: grid;
    gap: var(--s3);
    min-width: 0;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: "editor" "preview";
  }

  .editor {
    grid-area: editor;
  }

  .preview {
    grid-area: preview;
    overflow-y: auto;
  }

  :global(.preview) :global(pre) {
    overflow-x: auto;
  }

  textarea {
    width: 100%;
    padding: 12px 10px;
    font-size: 0.8rem;
    border-radius: 3px;
    border: 1px solid var(--border);
    color: var(--text-primary-color);
    resize: none;
    margin-bottom: 16px;
  }

  /* Mobile first tablet breakpoint */
  @media screen and (min-width: 640px) {
    .content-editor {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-areas: "editor preview";
    }
  }

  /* Mobile first desctop breakpoint */
  @media screen and (min-width: 1024px) {
  }
</style>
