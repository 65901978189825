<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import { link } from "svelte-navigator"

  import { drawer } from "../stores/store"
  import { isMobile } from "../utils"

  export let selected: boolean = false
  export let sub: boolean = false
  export let label: string
  export let href: string
  export let count: number | undefined = undefined

  const dispatch = createEventDispatcher<{ click: undefined }>()

  function handleClick() {
    if (isMobile()) {
      drawer.toggle()
    }
    dispatch("click")
  }
</script>

<a use:link class:sub class:selected class="menu-item" {href} on:click={handleClick}>
  <span>{label}</span>
  {#if count}
    <div class="count">{count}</div>
  {/if}
</a>

<style>
  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 10px 11px;
    color: var(--text-secondary-color);
    text-decoration: none;
    border-left: 4px solid transparent;
  }

  .count {
    font-size: 0.8rem;
    color: var(--text-unimportant-color);
  }

  .menu-item:hover {
    background-color: var(--bg-glass-primary-color);
  }

  .selected {
    font-weight: bold;
    color: var(--text-secondary-color);
    border-left: 4px solid var(--bg-glass-secondary-color);
  }

  .sub {
    padding: 10px 15px 10px 25px;
  }
</style>
