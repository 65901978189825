export type FsNodeRootType = "Root" | "Bin"

export type FsNodeType = "Directory" | "File" | "Image" | "Note" | FsNodeRootType

interface FileCommun {
  stem?: string
  extension?: string
  content_type: string
  hash: string
  size: number
}

export interface FileData extends FileCommun {
  type: "File"
}

export interface ImageData extends FileCommun {
  type: "Image"
  width: number
  height: number
}

export type DirectoryData = {
  type: "Directory"
  name: string
}

export interface ThumbnailData {
  type: "Thumbnail"
  content_type: string
  size: number
  width: number
  heigth: number
  query_width: number
  query_heigth: number
}

export interface RootData {
  type: "Root"
}

export interface BinData {
  type: "Bin"
}

export interface NoteData {
  type: "Note"
  name: string
  content: string
}

export type FsNodeData = RootData | BinData | DirectoryData | FileData | ImageData | ThumbnailData | NoteData

export function getFsNodeName(fsNodeData: FsNodeData): string | undefined {
  if (fsNodeData.type === "File" || fsNodeData.type === "Image") {
    return fsNodeData.stem
  } else if (fsNodeData.type === "Directory" || fsNodeData.type === "Note") {
    return fsNodeData.name
  }
}

export function getFsNodeExtension(fsNodeData: FsNodeData): string | undefined {
  if (fsNodeData.type === "File" || fsNodeData.type === "Image") {
    return `.${fsNodeData.extension}`
  }
}

export function isFsNodeRoot(fsNodeData: FsNodeData): fsNodeData is RootData {
  return fsNodeData.type === "Root"
}

export function isFsNodeBin(fsNodeData: FsNodeData): fsNodeData is BinData {
  return fsNodeData.type === "Bin"
}

export function isFsNodeDirectory(fsNodeData: FsNodeData): fsNodeData is DirectoryData {
  return fsNodeData.type === "Directory"
}

export function isFsNodeFile(fsNodeData: FsNodeData): fsNodeData is FileData {
  return fsNodeData.type === "File"
}

export function isFsNodeNote(fsNodeData: FsNodeData): fsNodeData is NoteData {
  return fsNodeData.type === "Note"
}

export function isFsNodeImage(fsNodeData: FsNodeData): fsNodeData is ImageData {
  return fsNodeData.type === "Image"
}

export function isFsNodeVideo(fsNodeData: FileData): boolean {
  return (
    fsNodeData.content_type === "video/mp4" ||
    fsNodeData.content_type === "video/webm" ||
    fsNodeData.content_type === "video/ogg"
  )
}

export function isFsNodePDF(fsNodeData: FileData): boolean {
  return fsNodeData.content_type === "application/pdf"
}

export function getRenameName(fsNodeData: FsNodeData): string | undefined {
  if (fsNodeData.type === "File" || fsNodeData.type === "Image") {
    return fsNodeData.stem || `.${fsNodeData.extension}`
  } else if (fsNodeData.type === "Directory" || fsNodeData.type === "Note") {
    return fsNodeData.name
  }
}

export interface FsNode {
  id: number
  parent_id?: number | null
  uuid: string
  data: FsNodeData
  user_uuid: string
  created_at: string
  updated_at: string
  deleted_at?: string | null
}

export interface FsNodesResponse {
  fs_node: FsNode
  childrens: FsNode[]
  ancestors: FsNode[]
}

export interface UploadError {
  error_message: string
  filename: string
}

export type UploadResult = UploadError | FsNode
