<script lang="ts">
  import { _ } from "svelte-i18n"
  import { navigate } from "svelte-navigator"

  import MenuItem from "../components/MenuItem.svelte"
  import Header from "../components/layout/Header.svelte"
  import Main from "../components/layout/Main.svelte"
  import SideMenu from "../components/layout/SideMenu.svelte"

  import type { ObjectiveType } from "./Objective"
  import DocumentationNodeItem from "./components/DocumentationNodeItem.svelte"
  import TableOfContent from "./components/TableOfContent.svelte"
  import { objectivesStore, objectivesTree } from "./stores/ObjectivesStore"

  export let uuid: string

  let types: ObjectiveType[] = ["Story", "ProjectRoot", "Task"]

  $: objectivesStore.loadObjectives(uuid, types)
</script>

<Header>
  <div>
    <button on:click={() => navigate(-1)}>{$_("back")}</button>
  </div>
</Header>
<SideMenu href="/objectives">
  <MenuItem label={$_("projects")} href="/objectives" />
  <MenuItem label={$_("members")} href={`/objectives/members/${uuid}`} />
  <TableOfContent objectivesTree={$objectivesTree} />
</SideMenu>
<Main>
  <div class="documentation">
    {#if $objectivesTree}
      <DocumentationNodeItem node={$objectivesTree} />
    {/if}
  </div>
</Main>

<style>
  .documentation {
    max-width: 21cm;
    padding: 2cm;
    background-color: var(--bg-primary-color);
    margin-top: 2.5cm;
    margin: auto;

    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  @media screen and (max-width: 21cm) {
    .documentation {
      width: 100%;
      padding: 16px;
    }
  }
</style>
