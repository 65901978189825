import { toast } from "@zerodevx/svelte-toast"

export const success = (message: string) =>
  toast.push(message, {
    intro: { y: 192 },
    pausable: true,
    theme: {
      "--toastBackground": "green",
      "--toastColor": "white",
      "--toastProgressBackground": "darkgreen"
    }
  })

export const failure = (message: string) =>
  toast.push(message, {
    intro: { y: 192 },
    pausable: true,
    theme: {
      "--toastBackground": "#D32F2F",
      "--toastColor": "white",
      "--toastProgressBackground": "#B71C1C"
    }
  })
