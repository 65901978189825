<script lang="ts">
  import { onMount } from "svelte"
  import { _ } from "svelte-i18n"

  import BookmarkIcon from "virtual:icons/mdi/bookmark-multiple-outline"
  import MoreIcon from "virtual:icons/mdi/dots-vertical"
  import StarMinusIcon from "virtual:icons/mdi/star-minus-outline"
  import StarPlusIcon from "virtual:icons/mdi/star-plus-outline"

  import Dropdown from "../../components/Dropdown.svelte"
  import DropdownItem from "../../components/DropdownItem.svelte"
  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import RssSourcesByCategoriesMenu from "../components/RssSourcesByCategoriesMenu.svelte"

  import type { RssFeed } from "../../rss_feeds/models/RssFeed"
  import { Api } from "../../services/Api"
  import type { ApiErrors } from "../../services/ApiError"
  import { defaultPagination } from "../../services/Pagination"
  import Card from "../../ui_kit/cards/Card.svelte"
  import EmptyPanel from "../../ui_kit/common/EmptyPanel.svelte"
  import { getRssFeedDescription, getRssFeedTitle, isMobile } from "../../utils"
  import { likedRssFeedsStore, loadLikedRssFeedsLoading } from "../stores/RssFeedsLikedStore"
  import { loadRssSourcesByCategories, rssSourcesStore } from "../stores/RssSourcesStore"

  let selectedRssFeedUuid: string | undefined = undefined

  onMount(loadRssSourcesByCategories)

  likedRssFeedsStore.loadLikedRssFeeds(defaultPagination).then(rssFeeds => {
    if (!isMobile()) {
      selectedRssFeedUuid = rssFeeds.length > 0 ? rssFeeds[0].uuid : undefined
    }
  })

  let page = 1
  let nextPageLoader = false
  $: handleNextPage = () => {
    nextPageLoader = true
    Api.rssFeeds
      .getRssFeeds("Liked", { offset: page * 30, limit: 30 })
      .then(rssFeeds => {
        page = page + 1
        nextPageLoader = false
        likedRssFeedsStore.concat(rssFeeds)
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
        nextPageLoader = false
      })
  }

  let likeLoadingUuid: string | undefined = undefined
  function reaction(rssFeed: RssFeed) {
    likeLoadingUuid = rssFeed.uuid
    Api.rssFeeds
      .updateRssFeed(rssFeed, rssFeed.reaction === "Liked" ? "Readed" : "Liked")
      .then(() => {
        likeLoadingUuid = undefined
        likedRssFeedsStore.removeItem(rssFeed)
      })
      .catch((errors: ApiErrors) => {
        // TODO handle error
        console.error(errors)
        likeLoadingUuid = undefined
      })
  }
</script>

<Header />
<SideMenu href="/rss/feeds">
  <div class="menu-items">
    <MenuItem label={$_("findNewRssSources")} href="/rss/sources/find" />
    <MenuItem label={$_("myRssSources")} href="/rss/sources" />
    <MenuItem selected label={$_("favorites")} href="/rss/feeds/liked" />
    <MenuItem
      label={$_("allCategories")}
      href="/rss/feeds"
      count={$rssSourcesStore.reduce((acc, i) => acc + i.unreaded, 0)}
    />
  </div>
  <div class="categories-menu">
    <RssSourcesByCategoriesMenu />
  </div>
</SideMenu>

<Main>
  <div class="rss-feeds" class:has-rss-feed-selected={selectedRssFeedUuid !== undefined}>
    <h1>{$_("rss.liked.title")}</h1>
    {#if $loadLikedRssFeedsLoading}
      <Loader size={40} />
    {:else}
      {#each $likedRssFeedsStore as rssFeed (rssFeed.uuid)}
        <Card
          title={getRssFeedTitle(rssFeed)}
          content={getRssFeedDescription(rssFeed)}
          href={`/rss/feeds/liked/view/${rssFeed.uuid}`}
          imgsrc={rssFeed.readable?.lead_image_url}
          --mb="var(--s6)"
        >
          <Dropdown right>
            <svelte:fragment slot="action">
              <button class="icon" aria-busy={likeLoadingUuid === rssFeed.uuid}>
                <MoreIcon />
              </button>
            </svelte:fragment>
            <svelte:fragment slot="items">
              <DropdownItem let:size label={$_("rss.liked.remove")} on:click={() => reaction(rssFeed)}>
                {#if rssFeed.reaction === "Liked"}
                  <StarMinusIcon width={size} height={size} />
                {:else}
                  <StarPlusIcon width={size} height={size} />
                {/if}
              </DropdownItem>
            </svelte:fragment>
          </Dropdown>
        </Card>
      {:else}
        <EmptyPanel label={$_("rss.liked.empty")} />
      {/each}
      {#if $likedRssFeedsStore.length !== 0}
        <div class="load-more">
          <button class="primary" on:click={handleNextPage} aria-busy={nextPageLoader}>{$_("seeMore")}</button>
        </div>
      {/if}
      {#if nextPageLoader}
        <div class="loader">
          <Loader size={70} />
        </div>
      {/if}
    {/if}
  </div>
</Main>

<style>
  .rss-feeds {
    max-width: var(--max-width);
    margin: auto;
  }

  .menu-items {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border);
  }

  .categories-menu {
    flex: 1;
    overflow-y: auto;
  }

  .load-more {
    padding: 15px 0 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--s5);
  }

  h1 {
    font-size: var(--fs-1);
    color: var(--color-dark);
  }
</style>
