<script lang="ts">
  import { _ } from "svelte-i18n"
  import { navigate } from "svelte-navigator"

  import ImagesViewer from "../../components/ImagesViewer.svelte"
  import Loader from "../../components/Loader.svelte"
  import MenuItem from "../../components/MenuItem.svelte"
  import Modal from "../../components/Modal.svelte"
  import Header from "../../components/layout/Header.svelte"
  import Main from "../../components/layout/Main.svelte"
  import SideMenu from "../../components/layout/SideMenu.svelte"

  import Breadcrumb from "../components/Breadcrumb.svelte"
  import CreateFsNodeActions from "../components/CreateFsNodeActions.svelte"
  import FlyingPanel from "../components/FlyingPanel.svelte"
  import FsNodeMoveModal from "../components/FsNodeMoveModal.svelte"
  import FsNodeSelection from "../components/FsNodeSelection.svelte"
  import FsNodes from "../components/FsNodes.svelte"
  import UploadPanel from "../components/UploadPanel.svelte"
  import Uploader from "../components/Uploader.svelte"

  import { Api } from "../../services/Api"
  import {
    FsNode,
    getFsNodeName,
    isFsNodeDirectory,
    isFsNodeFile,
    isFsNodeImage,
    isFsNodeNote,
    isFsNodePDF,
    isFsNodeRoot,
    isFsNodeVideo
  } from ".././models/FsNode"
  import {
    fsNodesStore,
    moveFsNodeActionLoading,
    multiMoveActionLoading,
    selectedFsNodes,
    wantCreateDirectory,
    wantCreateNote,
    wantMoveFsNode,
    wantMoveMultiFsNodes
  } from "../stores/FilesStore"
  import { upload, uploadFiles } from "../stores/UploadStore"

  export let uuid: string | undefined

  $: filesResponse = Api.fsNodes.getFiles(uuid, "Root").then(response => {
    fsNodesStore.set(response.childrens)
    selectedFsNodes.deselect()
    return response
  })

  let imagePreview: FsNode | undefined = undefined

  function getSrc(id: string): string {
    return Api.fsNodes.getThumbnailUri(id, 1500, 1500)
  }

  function handleFsNodeClick(fsNode: FsNode) {
    if (isFsNodeImage(fsNode.data)) {
      imagePreview = fsNode
    } else if (isFsNodeNote(fsNode.data)) {
      navigate(`/fs/note/${fsNode.uuid}`)
    } else if (isFsNodeFile(fsNode.data) && (isFsNodePDF(fsNode.data) || isFsNodeVideo(fsNode.data))) {
      navigate(`/fs/${fsNode.uuid}`)
    } else if (isFsNodeDirectory(fsNode.data)) {
      navigate(`/fs/${fsNode.uuid}`)
    }
  }
</script>

<Header />

<SideMenu href="/fs">
  <MenuItem label={$_("bin")} href="/fs/trash-bin" />
</SideMenu>

<Main>
  <div class="main">
    {#await filesResponse}
      <Loader size={40} />
    {:then files}
      {#if isFsNodeImage(files.fs_node.data) || !!imagePreview}
        <ImagesViewer
          ids={files.childrens.filter(n => isFsNodeImage(n.data)).map(n => n.uuid)}
          {getSrc}
          selected={imagePreview?.uuid ?? ""}
          alt=""
          on:close={() => {
            if (imagePreview) {
              imagePreview = undefined
            } else {
              navigate(-1)
            }
          }}
        />
      {:else}
        <div class="content">
          <div class="tools">
            <Breadcrumb ancestors={files.ancestors} />
            <div class="actions">
              {#if isFsNodeDirectory(files.fs_node.data) || isFsNodeRoot(files.fs_node.data)}
                <Uploader label={$_("upload")} on:upload={e => upload(files.fs_node.uuid, e.detail.files)} />
                <CreateFsNodeActions
                  on:create_note={() => wantCreateNote.update(v => !v)}
                  on:create_directory={() => wantCreateDirectory.update(v => !v)}
                />
              {/if}
            </div>
          </div>
          {#if isFsNodeDirectory(files.fs_node.data) || isFsNodeRoot(files.fs_node.data)}
            <FsNodes
              parentUuid={files.fs_node.uuid}
              on:click={({ detail }) => handleFsNodeClick(detail)}
              on:select={({ detail }) => selectedFsNodes.select(detail)}
            />
          {:else if isFsNodeFile(files.fs_node.data) && isFsNodePDF(files.fs_node.data)}
            <embed src={Api.fsNodes.getDownloadUri(files.fs_node.uuid, "Preview")} width="100%" height="100%" />
          {:else if isFsNodeFile(files.fs_node.data) && isFsNodeVideo(files.fs_node.data)}
            <!-- svelte-ignore a11y-media-has-caption -->
            <video class="video" controls width="100%">
              <source
                src={Api.fsNodes.getDownloadUri(files.fs_node.uuid, "Download")}
                type={files.fs_node.data.content_type}
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          {/if}
        </div>
      {/if}
      <FlyingPanel
        selected={$selectedFsNodes.length > 0 || $uploadFiles.length > 0}
        on:click={() => selectedFsNodes.deselect()}
      >
        <FsNodeSelection
          parent={files.fs_node}
          on:title_click={({ detail }) => {
            if (isFsNodeImage(detail.data)) {
              imagePreview = detail
            } else {
              navigate(`/fs/${detail.uuid}`)
            }
          }}
        />
        {#if $uploadFiles.length > 0}
          <UploadPanel />
        {/if}
      </FlyingPanel>
      {#if $wantMoveFsNode}
        <Modal
          title={`${$_("move")} ${getFsNodeName($wantMoveFsNode.data) || ""}`}
          on:close={() => wantMoveFsNode.set(undefined)}
        >
          <FsNodeMoveModal
            loading={$moveFsNodeActionLoading}
            startTarget={files.fs_node}
            on:move={({ detail: { target } }) => {
              if (files.fs_node.uuid === target.uuid) {
                wantMoveFsNode.set(undefined)
              } else if ($wantMoveFsNode) {
                moveFsNodeActionLoading.set(true)
                fsNodesStore.moveFsNodeAction($wantMoveFsNode, target).finally(() => moveFsNodeActionLoading.set(false))
              }
            }}
          />
        </Modal>
      {/if}
      {#if $wantMoveMultiFsNodes}
        <Modal
          title={`${$_("move")} ${$wantMoveMultiFsNodes.length} ${$_("elements")}`}
          on:close={() => wantMoveMultiFsNodes.set(undefined)}
        >
          <FsNodeMoveModal
            loading={$multiMoveActionLoading}
            startTarget={files.fs_node}
            on:move={({ detail: { target } }) => {
              if (files.fs_node.uuid === target.uuid) {
                wantMoveMultiFsNodes.set(undefined)
              } else if ($wantMoveMultiFsNodes) {
                multiMoveActionLoading.set(true)
                fsNodesStore.multiMoveAction($wantMoveMultiFsNodes, target).finally(() => {
                  wantMoveMultiFsNodes.set(undefined)
                  multiMoveActionLoading.set(false)
                  selectedFsNodes.deselect()
                })
              }
            }}
          />
        </Modal>
      {/if}
    {:catch error}
      <p style="color: red">{error.message}</p>
    {/await}
  </div>
</Main>

<style>
  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .tools {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .actions {
    display: flex;
    padding: var(--s3);
  }

  .video {
    padding: var(--s3);
    max-height: 80vh;
  }

  .main {
    height: calc(100vh - var(--header-height));
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-template-areas:
      "content"
      "side-panel";
  }

  @media screen and (min-width: 700px) {
    .main {
      grid-template-rows: auto;
      grid-template-columns: auto 300px;
      grid-template-areas: "content side-panel";
    }
  }
</style>
