import { writable } from "svelte/store"

function createDrawer() {
  const { subscribe, set, update } = writable(localStorage.getItem("MENU_STATE") === "true")
  return {
    subscribe,
    toggle: () => update(open => !open),
    close: () => set(false)
  }
}

export const drawer = createDrawer()

drawer.subscribe(value => {
  localStorage.setItem("MENU_STATE", String(value))
})
